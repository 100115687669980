/*
    Context: 
    
    This hook provides access to knockout's global view model
    which is used exhaustively throughout esx-web. The whole
    concept is 100% dynamic so we have no way of knowing what
    properties actually live on the viewModel so we provide a
    way to define the model you are expecting. 

    It's important to remember when defining knockout properties
    that they are mostly functions. For example to access the
    value of 'step' on the vm, you would need to define it as

    type TimesheetsImportModel = {
        step: () => number
    }
*/

export function useGlobalViewModel<T>(fallbackImplementation?: T): { vm: T } {
  //@ts-ignore
  const vm = window?.gp?.vm as T

  if (vm === undefined && fallbackImplementation !== undefined) {
    return {
      vm: fallbackImplementation
    }
  }

  return { vm }
}
