import * as React from 'react'

import { usePayPeriod } from '@local/payroll/experience/details/stores/PayPeriodStore'
import { PageLoader } from '@local/payroll/shared/components/pageLoader'

import { PageHeader } from '../../components/pageHeader'
import { Actions } from './actions'
import { Page } from '@local/payroll/shared/components/layout/page'

export const Header = () => {
  // We should be using route utils (e.g. routes.ts), but we currently don't support universal Payroll UUID
  // (This page only has access to Payroll SecureID.)
  // For now, use the existing architecture and just load redirect from the payperiod store.
  const employeesSpreadsheetUrl = usePayPeriod(
    (state) => state.payPeriod.employeesSpreadsheetUrl
  )

  const isLoading = usePayPeriod((state) => state.isLoading)
  if (isLoading) return <PageLoader />

  return (
    <Page>
      <PageHeader currentStep='Employee earnings'>
        <Actions
          className='self-end'
          previousUrl={employeesSpreadsheetUrl}
          nextUrl={employeesSpreadsheetUrl}
        />
      </PageHeader>
    </Page>
  )
}
