import * as React from 'react'

import { Topic } from './topic'

export interface TopicPublisher {
  publish(type: Topic<void>): void
  publish<T>(type: Topic<T>, value: T): void
}

export const TOPIC_PREFIX = 'ecPublisher:'
export const toTopicEventName = (name: string) => `${TOPIC_PREFIX}${name}`

/**
 * Provides a publisher for publishing on global topics.
 * This is used for decoupled, inter-app communication.
 * Topics are predefined and not created by any of the apps.
 * This should only be used sparingly and for things that effect multiple SPAs...
 * for example, when the global configuration changes.
 * @param [dispatcher] The dispatcher to use
 * @returns The publisher api
 */
export const useTopicPublisher = (
  dispatcher: Pick<Window, 'dispatchEvent'> = window
): TopicPublisher => {
  const publisher = React.useMemo(
    () => ({
      publish: (eventType: Topic, value?: any) => {
        dispatcher.dispatchEvent(
          new CustomEvent(toTopicEventName(eventType.type), { detail: value })
        )
      }
    }),
    [dispatcher]
  )

  return publisher
}

/**
 * Subscribes to a global topic.
 * @param eventType The global topic type
 * @param [callback] The callback function for the topic
 * @param [isActive] Whether the subscription is active. If the subscription is not
 *   active, then no handler will be subscribed to by the publisher.
 */
export const useTopicSubscription = <T>(
  eventType: Topic<T>,
  callback: (value: T) => void,
  isActive = true,
  dispatcher: Pick<Window, 'addEventListener' | 'removeEventListener'> = window
) => {
  const cb = React.useCallback(
    (event: CustomEvent) => callback(event.detail),
    [callback]
  )
  const topicEventName = toTopicEventName(eventType.type)

  React.useEffect(() => {
    if (isActive) {
      dispatcher.addEventListener(topicEventName as any, cb)
    }

    return () => dispatcher.removeEventListener(topicEventName as any, cb)
  }, [dispatcher, eventType, cb, isActive, topicEventName])
}
