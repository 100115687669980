import * as yup from 'yup'

export class ReportsSummary {
  readonly payrollSummaryReport: string
  readonly payrollExceptionReport: string
  readonly employeeDetailReport: string
  readonly employeeDetailReportByDept: string
  readonly employeeDetailReportByPosition: string
  readonly checkRegisterReport: string
  readonly employeeComparisonReport: string
  readonly directDepositReport: string
  readonly laborDistributionReportByDepartment: string | null | undefined
  readonly laborDistributionReportByLocation: string | null | undefined
  readonly laborDistributionReportByJob: string | null | undefined
  readonly journalEntryReport: string | null | undefined

  private static readonly schema = yup
    .object({
      payrollSummaryReport: yup.string().required(),
      payrollExceptionReport: yup.string().required(),
      employeeDetailReport: yup.string().required(),
      employeeDetailReportByDept: yup.string().required(),
      employeeDetailReportByPosition: yup.string().required(),
      checkRegisterReport: yup.string().required(),
      employeeComparisonReport: yup.string().required(),
      directDepositReport: yup.string().required(),
      laborDistributionReportByDepartment: yup.string().nullable(),
      laborDistributionReportByLocation: yup.string().nullable(),
      laborDistributionReportByJob: yup.string().nullable(),
      journalEntryReport: yup.string().nullable()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = ReportsSummary.schema.validateSync(obj)

    this.payrollSummaryReport = validObj.payrollSummaryReport
    this.payrollExceptionReport = validObj.payrollExceptionReport
    this.employeeDetailReport = validObj.employeeDetailReport
    this.employeeDetailReportByDept = validObj.employeeDetailReportByDept
    this.employeeDetailReportByPosition =
      validObj.employeeDetailReportByPosition
    this.checkRegisterReport = validObj.checkRegisterReport
    this.employeeComparisonReport = validObj.employeeComparisonReport
    this.directDepositReport = validObj.directDepositReport
    this.laborDistributionReportByDepartment =
      validObj.laborDistributionReportByDepartment
    this.laborDistributionReportByLocation =
      validObj.laborDistributionReportByLocation
    this.laborDistributionReportByJob = validObj.laborDistributionReportByJob
    this.journalEntryReport = validObj.journalEntryReport
  }

  static copy(reportsSummary: ReportsSummary): ReportsSummary {
    return new ReportsSummary(reportsSummary)
  }

  static of(
    payrollSummaryReport: string,
    payrollExceptionReport: string,
    employeeDetailReport: string,
    employeeDetailReportByDept: string,
    employeeDetailReportByPosition: string,
    checkRegisterReport: string,
    employeeComparisonReport: string,
    directDepositReport: string,
    laborDistributionReportByDepartment: string | null | undefined,
    laborDistributionReportByLocation: string | null | undefined,
    laborDistributionReportByJob: string | null | undefined,
    journalEntryReport: string | null | undefined
  ): ReportsSummary {
    return ReportsSummary.copy({
      payrollSummaryReport,
      payrollExceptionReport,
      employeeDetailReport,
      employeeDetailReportByDept,
      employeeDetailReportByPosition,
      checkRegisterReport,
      employeeComparisonReport,
      directDepositReport,
      laborDistributionReportByDepartment,
      laborDistributionReportByLocation,
      laborDistributionReportByJob,
      journalEntryReport
    })
  }
}
