import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { SpaErrorModel } from '@local/payroll/shared/models/spaErrorModel'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollEmployeeFactory } from '@local/payroll/shared/models/factories'
import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'

// This resource hits esx-web
export const DeleteEmployeeEarningResource = new Resource<PayrollAction>(
  new PayrollActionSerializer(),
  ':client/payroll/:payroll_id/employees/:employee_id/earning/:earning_id'
)

withMirageServerDo((server, _faker) => {
  server.delete(
    DeleteEmployeeEarningResource.endpointTemplate(),
    function (schema, request) {
      const { employee_id, earning_id } = request.params

      const employee: PayrollEmployee = schema.db.payrollEmployee.findBy({
        employeeId: employee_id
      })

      const copyOfEmployee = PayrollEmployeeFactory.create({
        ...employee,
        paystubs: employee.paystubs.map((ps) => {
          return {
            ...ps,
            earnings: ps.earnings.filter(
              (existing) => existing.id !== earning_id
            )
          }
        })
      })

      if (Math.random() <= 0.1) {
        console.warn('we have decided to randomly break, do not be alarmed')
        return PayrollAction.of(
          0,
          [SpaErrorModel.of(500, 'Server Error', '')],
          ''
        )
      }

      return PayrollAction.of(copyOfEmployee)
    },
    { timing: 2000 }
  )
})
