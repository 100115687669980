import * as yup from 'yup'
import { SpaDateTime } from '@local/shared-services'

export class PaperCheck {
  readonly payPeriodCheckUuid: string
  readonly pdfFileName: string | undefined | null
  readonly createdAt: SpaDateTime

  private static readonly schema = yup
    .object({
      payPeriodCheckUuid: yup.string().required(),
      pdfFileName: yup.string(),
      createdAt: yup.mixed().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = PaperCheck.schema.validateSync(obj)

    this.payPeriodCheckUuid = validObj.payPeriodCheckUuid
    this.pdfFileName = validObj.pdfFileName
    this.createdAt = SpaDateTime.fromString(validObj.createdAt)
  }

  static copy(source: PaperCheck): PaperCheck {
    return new PaperCheck(source)
  }

  static of(
    payPeriodCheckUuid: string,
    pdfFileName: string | undefined | null,
    createdAt: SpaDateTime
  ): PaperCheck {
    return PaperCheck.copy({
      payPeriodCheckUuid,
      pdfFileName,
      createdAt
    })
  }
}
