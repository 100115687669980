import * as yup from 'yup'

export interface PayPeriodAchSummary {
  collectionChecks: number
  collectionDirectDeposits: number
  collectionVendorChecks: number
  collectionVendorDirectDeposits: number
  collectionFees: number
}

export class AchSummary {
  readonly collectionChecks: number
  readonly collectionDirectDeposits: number
  readonly collectionVendorChecks: number
  readonly collectionVendorDirectDeposits: number
  readonly collectionFees: number

  private static readonly schema = yup
    .object({
      collectionChecks: yup.number().required(),
      collectionDirectDeposits: yup.number().required(),
      collectionVendorChecks: yup.number().required(),
      collectionVendorDirectDeposits: yup.number().required(),
      collectionFees: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = AchSummary.schema.validateSync(obj)

    this.collectionChecks = validObj.collectionChecks
    this.collectionDirectDeposits = validObj.collectionDirectDeposits
    this.collectionVendorChecks = validObj.collectionVendorChecks
    this.collectionVendorDirectDeposits =
      validObj.collectionVendorDirectDeposits
    this.collectionFees = validObj.collectionFees
  }

  static copy(source: AchSummary): AchSummary {
    return new AchSummary(source)
  }

  static of(
    collectionChecks: number,
    collectionDirectDeposits: number,
    collectionVendorChecks: number,
    collectionVendorDirectDeposits: number,
    collectionFees: number
  ): AchSummary {
    return AchSummary.copy({
      collectionChecks,
      collectionDirectDeposits,
      collectionVendorChecks,
      collectionVendorDirectDeposits,
      collectionFees
    })
  }
}
