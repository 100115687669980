import { gql, useMutation } from '@apollo/client'
import { useServerRedirect } from '@local/payroll/shared/hooks/useRedirect'

import { useAppError } from '@local/payroll/shared/hooks/useAppError'
import { SpaErrorModel } from '@local/payroll/shared/models/spaErrorModel'

import {
  ResetMutationVariables,
  ResetMutation
} from '@local/payroll/gen/queries'
import { ModalHooksFor } from '../stores/ModalStore'
import { usePayrollParams } from './useMigrationParams'

export const RESET_QUERY = gql`
  mutation reset($payPeriodUuid: ID!) {
    reset(payPeriodUuid: $payPeriodUuid)
  }
`

const { useIsModalOpen, useOpenModal, useCloseModal } =
  ModalHooksFor('ResetPayroll')

/**
 *
 * @returns
 *
 *  isLoading - if the reset is in progress
 *
 *  error - the error returned from the reset mutation
 *
 *  isOpen - if the reset payroll modal is open
 *
 *  open - opens the reset payroll modal
 *
 *  close - closes the reset payroll modal
 *
 *  reset - run the mutation to reset the payroll
 *
 */
export const useResetPayrollModal = () => {
  const { client } = usePayrollParams()
  const listUrl = `/${client}/payroll/list`
  const isOpen = useIsModalOpen()
  const open = useOpenModal()
  const close = useCloseModal()

  const { useSetAppError } = useAppError()

  const setAppError = useSetAppError()
  const redirect = useServerRedirect()

  const [reset, { error, loading: isLoading }] = useMutation<
    ResetMutation,
    ResetMutationVariables
  >(RESET_QUERY, {
    onCompleted: () => {
      close()
      redirect(listUrl)
    },
    onError: (error) => {
      close()
      setAppError({
        spaError: SpaErrorModel.of(
          // TS doesn't like that it's possible for networkError to have no statusCode field --
          // we accept the fallback in this case
          // @ts-ignore
          error.networkError?.statusCode || 500,
          error.message
        ),
        action: 'reset'
      })
    }
  })

  return {
    isLoading,
    error,
    isOpen,
    open,
    close,
    reset: (payPeriodUuid: string) => {
      reset({ variables: { payPeriodUuid } })
    }
  }
}
