import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'

import { useGlobalViewModel } from '@local/payroll/shared/hooks/legacy/useGlobalViewModel'
import { useWaitForUIState } from '@local/payroll/shared/hooks/useWaitForUIState'

type TimesheetsImportModel = {
  step: () => number
  stepNextConfirmed: () => void
  stepPrevious: () => void
  leftMenu: {
    nextLink: (value: string) => string
    previousLink: (value: string) => string
  }
}

type NextButtonProps = {
  previousUrl: string
  nextUrl: string
  className?: string
}

const fallbackVM = {
  step() {
    return 2
  },
  stepNextConfirmed() {
    window.history.back()
  },
  stepPrevious() {
    window.history.back()
  },
  leftMenu: {
    nextLink() {
      return ''
    },
    previousLink() {
      return ''
    }
  }
}

export const Actions = ({
  className,
  nextUrl,
  previousUrl
}: NextButtonProps) => {
  const { t } = useTranslation('payroll')
  const { vm } = useGlobalViewModel<TimesheetsImportModel>(fallbackVM)
  const [canGoNext, isNextErrored] = useWaitForUIState(() => vm.step() === 2)
  const [canGoPrevious, isPreviousErrored] = useWaitForUIState(
    () => vm.step() === 0
  )

  const handlePrevious = React.useCallback(() => {
    vm.leftMenu.previousLink(previousUrl)
    vm.stepPrevious()
  }, [previousUrl, vm])

  const handleNext = React.useCallback(() => {
    vm.leftMenu.nextLink(nextUrl)
    vm.stepNextConfirmed()
  }, [nextUrl, vm])

  if (isNextErrored) {
    console.warn(
      'unable to display next buttons because window.gp.vm.step() returned an error'
    )
    return null
  }

  // TODO: Are we doing to confirmation? If so this needs to be updated.

  return (
    <ButtonGroup>
      {isPreviousErrored ? null : (
        <Button
          variant='secondary'
          disabled={!canGoPrevious}
          onClick={handlePrevious}
          className={className}
        >
          {t('common.previous')}
        </Button>
      )}
      <Button
        variant='primary'
        disabled={!canGoNext}
        onClick={handleNext}
        className={className}
      >
        {t('common.next')}
      </Button>
    </ButtonGroup>
  )
}
