// NOTE: secureId is the deprecated payroll ID mechanism used by ec-web
// As we migrate to ec-api/GQL, we prefer uuid, but must continue to pass around secureId
// as a query param so that existing endpoints have a way to access it.

import { generatePath } from 'react-router-dom'

// TODO: some routes are still missing here. they are to be added gradually as the payroll workflow is refactored

// NOTE: all the paths in this file use `payroll_id` instead of a more standard naming such as `payrollUuid`.
// This is for compatibility with PayPeriodProvider, which is currently reliant on a param named payroll_id,
// which may be a legacy payroll id (payrollSecureId). As we remove payrollSecureId and refactor PayPeriodProvider,
// we can eventually systematically change all of the route params to use :payrollUuid instead.

export const detailEmployeesPathBase = `/:client/payroll/:payroll_id/employees`

export const detailEmployeesPath = ({
  client,
  uuid
}: {
  client: string
  uuid: string
}) =>
  `${generatePath(detailEmployeesPathBase, {
    client,
    payroll_id: uuid
  })}`

export const detailReviewPathBase = `/:client/payroll/:payroll_id/review`

export const detailReviewPath = ({
  client,
  uuid
}: {
  client: string
  uuid: string
}) =>
  `${generatePath(detailReviewPathBase, {
    client,
    payroll_id: uuid
  })}`

// TODO: The Preflight page is slightly unique in that it must handle both UUID and SecureID, because
// it is the entry point from a "UUID" flow but must connect to the legacy flow that is still
// reliant on Web/SecureID endpoints. As those endpoints are gradually removed, and preflight
// has no reason to use SecureID anymore, then it can also drop the query param.

// TODO: The Preflight page should ideally also be written /:client/payroll/:payroll_id:/preview
// However, it was the first page to be transformed, has no proxy through ec-web (see comments in DetailsRoute),
// and is thus less safe to actively change the URL for. Like the other changes, it can be safely
// changed once the reliance on Web/SecureID is removed.
export const detailPreflightPathBase = `/:client/payroll/details/preflight/:payroll_id`

export const detailPreflightPath = ({
  client,
  uuid,
  secureId
}: {
  client: string
  uuid: string
  secureId: string
}) =>
  `${generatePath(detailPreflightPathBase, {
    client,
    payroll_id: uuid
  })}?id=${secureId}`
