import * as yup from 'yup'

import { PayCheckEarning } from '@local/payroll/shared/types/graphDeprecated'
import { CheckCodeFactory } from './CheckCodeFactory'
import { EarningCodeFactory } from './EarningCodeFactory'

export const EmployeeEarningFactory = {
  copy(source: PayCheckEarning) {
    return this.create(source)
  },
  create(obj: unknown): PayCheckEarning {
    const earning = yup
      .object({
        id: yup.string().required(),
        uuid: yup.string().required(), // added .required
        name: yup.string().defined(),
        location: yup.string().defined().nullable(),
        job: yup.string().defined().nullable(),
        rate: yup.number().required(),
        baseRate: yup.number().required(), // added .required
        hours: yup.number().required(),
        checkCode: yup.object().required(),
        earningCode: yup.object().required(),
        isFirstWeek: yup.boolean().required(),
        amount: yup.number().required()
      })
      .defined()
      .validateSync(obj)

    return {
      ...earning,
      checkCode: CheckCodeFactory.create(earning.checkCode),
      earningCode: EarningCodeFactory.create(earning.earningCode)
    }
  }
}
