import { FactorySerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { ReportsSummary } from '@local/payroll/experience/details/models'

const ReportsSummarySerializer = new FactorySerializer(
  (payload) => new ReportsSummary(payload)
)

// resource hits esx-web
const ReportsSummaryResource = new Resource<ReportsSummary>(
  ReportsSummarySerializer,
  ':client/payroll/details/reports/:payroll_id'
)

export { ReportsSummaryResource, ReportsSummary }

//#region Mirage support
withMirageServerDo((server) => {
  server.db.createCollection(
    'payrollReviewReports',
    [
      ReportsSummary.of(
        'https://google.com',
        'https://google.com',
        'https://google.com',
        'https://google.com',
        'https://google.com',
        'https://google.com',
        '../employeeComparisonReport/calculated',
        'https://google.com',
        'https://www.google.com/search?q=labor+distribution+by+department',
        'https://www.google.com/search?q=labor+distribution+by+location',
        'https://www.google.com/search?q=labor+distribution+by+job',
        'https://www.google.com/search?q=gl+report'
      )
    ].map((r) => ReportsSummaryResource.serializer.serialize(r))
  )

  server.get(
    ReportsSummaryResource.endpointTemplate(),
    function (schema) {
      return schema.db.payrollReviewReports.find(1)
    },
    { timing: 4000 }
  )
})
//#endregion
