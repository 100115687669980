import * as React from 'react'
import cx from 'classnames'

type TestingProps = {
  testId?: string
}

type SectionTitleProps = {
  title: string
  description: string
  children?: React.ReactElement
} & React.JSX.IntrinsicElements['div'] &
  TestingProps

export const SectionTitle = ({
  title,
  description,
  children,
  testId = '',
  ...props
}: SectionTitleProps) => {
  const hasRightPanel = React.Children.count(children) > 0
  return (
    <div className='mb-6' {...props} data-testid={testId}>
      <div className='flex flex-col-reverse sm:flex-row sm:justify-between'>
        <div className={cx({ 'max-w-screen-md': hasRightPanel })}>
          <div className='mb-2 type-headline-3'>{title}</div>
          <div className='type-default text-secondary'>{description}</div>
        </div>
        {hasRightPanel ? (
          <div className='flex justify-end flex-shrink-0 min-w-0'>
            {children}
          </div>
        ) : null}
      </div>
    </div>
  )
}
