import * as React from 'react'

import {
  MerryGoRound,
  Skeleton,
  SkeletonProps
} from '@toasttab/buffet-pui-loading-indicators'

export const PageLoader = () => {
  return (
    <div className='flex items-center justify-center p-24'>
      <MerryGoRound size='md' />
    </div>
  )
}

export const ModalLoader = () => (
  <div className='flex items-center justify-center p-8'>
    <MerryGoRound />
  </div>
)

export const SectionLoader = () => (
  <div className='flex flex-col items-center justify-center h-full'>
    <MerryGoRound />
  </div>
)

export const SkeletonLoader = (props: SkeletonProps) => (
  <Skeleton className='w-full' {...props} />
)
