import * as yup from 'yup'
export class AccountingSummary {
  readonly previousGrossPay: number
  readonly currentGrossPay: number
  readonly currentEmployerTaxes: number
  readonly currentTotalTaxes: number
  readonly totalTips: number
  readonly cashTips: number
  readonly nonCashTips: number
  readonly total: number

  private static readonly schema = yup
    .object({
      previousGrossPay: yup.number().required(),
      currentGrossPay: yup.number().required(),
      currentEmployerTaxes: yup.number().required(),
      currentTotalTaxes: yup.number().required(),
      totalTips: yup.number().required(),
      cashTips: yup.number().required(),
      nonCashTips: yup.number().required(),
      total: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = AccountingSummary.schema.validateSync(obj)

    this.previousGrossPay = validObj.previousGrossPay
    this.currentGrossPay = validObj.currentGrossPay
    this.currentEmployerTaxes = validObj.currentEmployerTaxes
    this.currentTotalTaxes = validObj.currentTotalTaxes
    this.totalTips = validObj.totalTips
    this.cashTips = validObj.cashTips
    this.nonCashTips = validObj.nonCashTips
    this.total = validObj.total
  }

  static copy(accountingSummary: AccountingSummary) {
    return new AccountingSummary(accountingSummary)
  }

  static of(
    previousGrossPay: number,
    currentGrossPay: number,
    currentEmployerTaxes: number,
    currentTotalTaxes: number,
    totalTips: number,
    cashTips: number,
    nonCashTips: number,
    total: number
  ) {
    return AccountingSummary.copy({
      previousGrossPay,
      currentGrossPay,
      currentEmployerTaxes,
      currentTotalTaxes,
      totalTips,
      cashTips,
      nonCashTips,
      total
    })
  }
}
