import * as React from 'react'
import { Portal } from '@toasttab/ec-layout'
import { EcPageHeader } from '@toasttab/ec-ui-page-header'

type Props = React.ComponentProps<typeof EcPageHeader>

export const PageTitle = (props: Props) => (
  <Portal
    id='page-header'
    skipRenderIfNotFound={false}
    component={<EcPageHeader {...props} />}
  />
)
