import { RestResource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import {
  EnumMessageAmountType,
  EnumMessageCategory,
  PayrollMessage
} from '@local/payroll/experience/details/models'

export const MessagesResource = new RestResource<PayrollMessage>({
  // This resource hits esx-web
  resource: ':client/payroll/details/messages/:payroll_id'
})

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.db.createCollection(
    'messages',
    [
      new PayrollMessage(
        34.17,
        EnumMessageAmountType.Currency,
        EnumMessageCategory.ToDo,
        'Bonus',
        `${faker.name.firstName()} ${faker.name.lastName()}`,
        `${faker.name.firstName()} ${faker.name.lastName()}`
      ),
      new PayrollMessage(
        17.25,
        EnumMessageAmountType.Value,
        EnumMessageCategory.ToDo,
        'Hours',
        `${faker.name.firstName()} ${faker.name.lastName()}`,
        `${faker.name.firstName()} ${faker.name.lastName()}`
      ),
      new PayrollMessage(
        1000,
        EnumMessageAmountType.Currency,
        EnumMessageCategory.Check,
        'Void',
        `${faker.name.firstName()} ${faker.name.lastName()}`,
        `${faker.name.firstName()} ${faker.name.lastName()}`
      ),
      new PayrollMessage(
        93,
        EnumMessageAmountType.Currency,
        EnumMessageCategory.Void,
        'Manual Check',
        `${faker.name.firstName()} ${faker.name.lastName()}`,
        `${faker.name.firstName()} ${faker.name.lastName()}`
      ),
      new PayrollMessage(
        10,
        EnumMessageAmountType.Percent,
        EnumMessageCategory.Void,
        'Deduction',
        `${faker.name.firstName()} ${faker.name.lastName()}`,
        `${faker.name.firstName()} ${faker.name.lastName()}`
      )
    ].map((e) => MessagesResource.serializer.serialize(e))
  )
  server.get(
    MessagesResource.endpointTemplate(),
    function (schema) {
      return schema.db.messages
    },
    { timing: 2000 }
  )
})
//#endregion
