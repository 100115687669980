import * as React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'

import { Portal } from '@toasttab/ec-layout'
import { PageLoader } from '@local/payroll/shared/components/pageLoader'

import { CustomTimeImportHeader, AlohaTimeImportHeader } from './timeImport'
import { ResetPayrollModal } from './ResetPayrollModal'
import { PayPeriodProvider } from './stores/PayPeriodStore'

import { LazyEmployees, LazyReviewPage } from './lazy-pages'
import { useMigrationParams } from './hooks/useMigrationParams'

const PayPeriodStoreProviders = () => {
  // All pages within this provider should be assumed to be using secureID.
  // If a page is no longer using secureID, it should be hoisted out of this provider, the way preflight has been hoisted.
  const { client, payrollSecureId } = useMigrationParams()

  if (!payrollSecureId) throw new Error('Payroll UUID not found.')

  return (
    <React.Suspense fallback={<PageLoader />}>
      <PayPeriodProvider client={client!} payroll_id={payrollSecureId}>
        <ResetPayrollModal />
        <Outlet />
      </PayPeriodProvider>
    </React.Suspense>
  )
}

// This routing section is separated because of its reliance on the PayPeriodStore via providers.
// That reliance will disappear as we deprecated Web/SecureID in favor of UUID based routing.
// Eventually, it should be movable entirely back inside PayrollApplication.
export const ProviderRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          // WARNING: You must extract this component so that we don't try to load params that possibly don't exist
          // Until we actually try to load a child page [where we would know that param *does* exist]
          <PayPeriodStoreProviders />
        }
      >
        <Route
          path={'/mvc/:client/Company/Payroll/TimeImport/:payroll_id'}
          element={
            <Portal id='page-header' component={<CustomTimeImportHeader />} />
          }
        />

        <Route
          path='/mvc/:client/Company/Payroll/AlohaLaborReport/:payroll_id'
          element={
            <Portal id='page-header' component={<AlohaTimeImportHeader />} />
          }
        />

        {/* TODO: Delete these next two routes once esx-web proxy redirect has been removed. (See below.)*/}
        <Route
          path='/:client/payroll/employees/spreadsheet/:payroll_id'
          element={<LazyEmployees />}
        />
        <Route
          path='/:client/payroll/Details/Review/:payroll_id'
          element={<LazyReviewPage />}
        />
        {/* 
          These two routes have NO effect, because currently esx-web intercepts the routes and then redirects to the URLs listed above.
          Historically, this was done for backwards comaptibility with payrollSecureId, but we no longer need that support.
          Instead, we prefer this syntax, because they follow standardized routing conventions and are predictably named.

          <Route path={detailEmployeesPathBase} element={<LazyEmployees />} />
          <Route path={detailReviewPathBase} element={<LazyEmployees />} />

          When we delete the redirects in esx-web, then these routes should remain as the only "correct" routes.
        */}
      </Route>
      <Route path='*' element={null} />
    </Routes>
  )
}
