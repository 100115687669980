import * as yup from 'yup'
import { SpaErrorModel } from './spaErrorModel'

export class PayrollAction<T = any> {
  readonly result?: T
  readonly errors?: SpaErrorModel[]
  readonly redirectUrl?: string | null

  private static readonly schema = yup
    .object({
      result: yup.mixed().notRequired().nullable(),
      errors: yup.array().notRequired().nullable(),
      redirectUrl: yup.string().notRequired().nullable()
    })
    .defined()

  constructor(obj: unknown, TCreator?: (obj: unknown) => T) {
    const validObj = PayrollAction.schema.validateSync(obj)

    // Some "new PayrollAction" callsites have generic parameters, which
    // this constructor supports by permitting an undefined TCreator.
    //
    // This design preserves current behavior while we work towards full
    // adoption of the serialization design.
    //
    // TODO: make TCreator required.
    this.result = TCreator
      ? TCreator(validObj.result)
      : (validObj.result as unknown as T)
    this.errors = validObj.errors?.map((error) => new SpaErrorModel(error))
    this.redirectUrl = validObj.redirectUrl
  }

  // Unlike other "of" functions, this one cannot check whether the result argument
  // is the right type, and so it punts. This makes the caller responsible for passing
  // in an object that has the right prototype.
  static of<T>(
    result?: T,
    errors?: SpaErrorModel[],
    redirectUrl?: string
  ): PayrollAction<T> {
    return new PayrollAction({ result, errors, redirectUrl })
  }
}
