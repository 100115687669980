import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { SpaErrorModel } from '@local/payroll/shared/models/spaErrorModel'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollEmployeeFactory } from '@local/payroll/shared/models/factories'
import {
  PayCheck,
  PayCheckDeduction,
  PayrollEmployee
} from '@local/payroll/shared/types/graphDeprecated'

export const DeleteDeductionMutation = async ({
  client,
  payroll_id,
  employee_id,
  deduction
}: {
  client: string
  payroll_id: string
  employee_id: string
  deduction: PayCheckDeduction
  paystub: PayCheck
}): Promise<PayrollEmployee> => {
  const response = await DeleteEmployeeDeductionResource.getStore({
    client,
    payroll_id,
    employee_id,
    deduction_id: deduction.id
  }).deleteRecord(PayrollAction.of())

  if (response.errors) {
    throw PayrollAction.of(deduction, response.errors)
  }

  return PayrollEmployeeFactory.create(response.result)
}

// This resource hits esx-web
export const DeleteEmployeeDeductionResource = new Resource<PayrollAction>(
  new PayrollActionSerializer(),
  ':client/payroll/:payroll_id/employees/:employee_id/deduction/:deduction_id'
)

withMirageServerDo((server, _faker) => {
  server.delete(
    DeleteEmployeeDeductionResource.endpointTemplate(),
    function (schema, request) {
      const { employee_id, deduction_id } = request.params

      const employee: PayrollEmployee = schema.db.payrollEmployee.findBy({
        employeeId: employee_id
      })

      const copyOfEmployee = PayrollEmployeeFactory.create({
        ...employee,
        paystubs: employee.paystubs.map((ps) => {
          return {
            ...ps,
            deductions: ps.deductions.filter(
              (existing) => existing.id !== deduction_id
            )
          }
        })
      })

      if (Math.random() <= 0.1) {
        console.warn('we have decided to randomly break, do not be alarmed')
        return PayrollAction.of(
          0,
          [SpaErrorModel.of(500, 'Server Error', '')],
          ''
        )
      }

      return PayrollAction.of(copyOfEmployee)
    }
  )
})
