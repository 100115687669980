import * as React from 'react'

import { BaseStepProps, Stepper, LinkStep } from '@toasttab/buffet-pui-stepper'

import { ScreenSize } from '@toasttab/use-screen-size'
import { StepModel } from './usePageHeader'

interface PageHeaderStepperProps {
  steps: StepModel[]
  screenSize: ScreenSize
}

export const PageHeaderStepper: React.FC<PageHeaderStepperProps> = ({
  steps,
  screenSize
}) => {
  const stepsWithLinks = steps.map(
    (step: StepModel): BaseStepProps =>
      !step.link
        ? step
        : {
            ...step,
            renderStep: (props) => (
              <LinkStep
                {...props}
                key={`link_${step.title}`}
                link={{
                  target: '_parent',
                  to: step.link!
                }}
              />
            )
          }
  )
  return (
    <div data-testid='payroll-stepper' className='flex-grow w-full'>
      <Stepper
        containerClassName='mx-auto'
        orientation={
          screenSize >= ScreenSize.SM
            ? 'horizontal-text-side'
            : 'horizontal-text-below'
        }
        size={screenSize >= ScreenSize.SM ? 'default' : 'sm'}
        steps={stepsWithLinks}
        testId='buffet-stepper'
      />
    </div>
  )
}
