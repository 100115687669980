import { useTranslation } from 'react-i18next'
import * as React from 'react'
import cx from 'classnames'
import {
  TimeClockIcon,
  WarningOutlineIcon,
  BulletIcon
} from '@toasttab/buffet-pui-icons'
import { SpaDateTime } from '@local/shared-services'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import { PostDeadline } from '@local/payroll/gen/queries'
import { Badge } from '@toasttab/buffet-pui-badge'

const DueDateFormatted = (
  deadlineUtc: SpaDateTime,
  isToday: boolean
): string => {
  const { t } = useTranslation('payroll')
  if (isToday) {
    // "Today, 4PM EDT"
    return `${t('payrolls.dueDate.today')}, ${deadlineUtc.toFormat('ha ZZZZ')}`
  }

  return deadlineUtc.toFormat('EEE, MMM d')
}

const getTextColorClasses = (isPastDue: boolean): string =>
  isPastDue ? 'text-warning' : 'text-default'

const DueDateIcon = (isToday: boolean, isPastDue: boolean) => {
  if (isToday) {
    return (
      <TimeClockIcon
        accessibility='decorative'
        className='inline-flex -mt-px text-warning'
      />
    )
  }
  if (isPastDue) {
    return (
      <WarningOutlineIcon
        accessibility='decorative'
        className='inline-flex -mt-px text-warning'
      />
    )
  }
  return null
}

export const getDateInfo = (deadlineUtc: SpaDateTime) => {
  const isPastDue = SpaDateTime.fromNow().isGreater(deadlineUtc)
  const isToday = !isPastDue && deadlineUtc.isToday()
  const dateFormatted = DueDateFormatted(deadlineUtc, isToday)

  return { dateFormatted, isToday, isPastDue }
}

const InvalidDateDisplay = () => {
  const { t } = useTranslation('payroll')

  return <div className='text-error'>{t('errors.prohibited')}</div>
}

type DueDateProps = {
  // TODO: remove undefined possibility, see below
  postDeadline?: PostDeadline
  deadlineUtc?: SpaDateTime | null
  displayType?: DueDateDisplayType
  status?: PayrollStatus
}

type DueDateDisplayType = 'badge' | 'pin' | 'smallText' | 'none'

export const DueDate = ({
  postDeadline,
  deadlineUtc,
  displayType = 'none',
  status
}: DueDateProps) => {
  // TODO: We are inferring whether the postDeadline is marked as prohibited based on
  // whether the dueDate is null, because esx-web does not deliver the actual postDeadline field itself.
  // ec-api has been refactored to just have the postDeadline in the PayPeriod object--
  // delete this code once all contexts using DueDate have been refactored to retrieve PayPeriod from ec-api instead
  if (postDeadline === PostDeadline.NotAllowedToPost || !deadlineUtc) {
    return <InvalidDateDisplay />
  }
  const { isPastDue, isToday, dateFormatted } = getDateInfo(deadlineUtc)

  if (displayType !== 'none') {
    return (
      <DueDateStatusColor
        displayType={displayType}
        status={status!}
        isPastDue={isPastDue}
        deadlineUtc={deadlineUtc}
        isToday={isToday}
      />
    )
  }

  return (
    <div
      className={cx(
        'flex items-center type-headline-5',
        getTextColorClasses(isPastDue)
      )}
    >
      {dateFormatted}
      <span className='flex flex-col md:pl-2 sm:pl-0 sm:mr-2'>
        {DueDateIcon(isToday, isPastDue)}
      </span>
    </div>
  )
}
interface DueDateEmbeddedProps {
  status: PayrollStatus
  displayType: DueDateDisplayType
  deadlineUtc: SpaDateTime
  isToday: boolean
  isPastDue: boolean
}

const isPosted = (status: PayrollStatus) =>
  [PayrollStatus.posted, PayrollStatus.generated_ach].includes(status)

const TextWithPin = ({
  text,
  pinColor
}: {
  text: string
  pinColor?: 'error' | 'warning'
}) => (
  <div className='flex'>
    {pinColor && (
      <BulletIcon
        accessibility='decorative'
        size='lg'
        className={cx('-ml-5 -mt-3 w-2 h-2 mr-7', {
          'text-error': pinColor === 'error',
          'text-warning': pinColor === 'warning'
        })}
      />
    )}
    <span>{text}</span>
  </div>
)

const DueDateStatusColor = ({
  status,
  displayType,
  deadlineUtc,
  isToday,
  isPastDue
}: DueDateEmbeddedProps) => {
  const { t } = useTranslation('payroll')
  const hasBeenPosted = isPosted(status)
  const deadline = deadlineUtc.toFormat('EEE, MMM d')

  if (displayType === 'smallText') {
    return (
      <span data-testid='due-ok'>
        {t('payrolls.dueDate.due', { deadline: deadline })}
      </span>
    )
  }

  if (hasBeenPosted) {
    return displayType === 'badge' ? (
      <Badge color='success'>
        <span data-testid='due-ok'>
          {t('payrolls.dueDate.due', { deadline: deadline })}
        </span>
      </Badge>
    ) : (
      <TextWithPin text={deadline} />
    )
  }

  if (isPastDue) {
    return displayType === 'badge' ? (
      <Badge color='error'>
        <span data-testid='due-past'>
          {t('payrolls.dueDate.due', { deadline: deadline })}
        </span>
      </Badge>
    ) : (
      <TextWithPin text={deadline} pinColor='error' />
    )
  }

  if (isToday) {
    return displayType === 'badge' ? (
      <Badge color='warning'>
        <span data-testid='due-today'>{`${t(
          'payrolls.dueDate.today'
        )}, ${deadlineUtc.toFormat('ha ZZZZ')}`}</span>
      </Badge>
    ) : (
      <TextWithPin
        text={`${t('payrolls.dueDate.today')}, ${deadlineUtc.toFormat(
          'ha ZZZZ'
        )}`}
        pinColor='warning'
      />
    )
  }

  return displayType === 'badge' ? (
    <Badge color='warning'>
      <span data-testid='due-regular'>
        {t('payrolls.dueDate.due', { deadline: deadline })}
      </span>
    </Badge>
  ) : (
    <TextWithPin text={deadline} />
  )
}
