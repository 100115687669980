import * as yup from 'yup'
import { SpaDateTime } from '@local/shared-services'
import { EnumToValueArray } from '@local/payroll/shared/utils/enumHelper'

export enum AuditAction {
  Unknown = 0,
  Opened = 1,
  Reset = 2,
  Posted = 3,
  Postponed = 4,
  Checks = 5,
  Other = 100
}

export class Audit {
  action: AuditAction
  whom: string
  date: SpaDateTime
  postponedDate: string | null | undefined

  private static readonly schema = yup
    .object({
      action: yup
        .number()
        .required()
        .oneOf(EnumToValueArray<AuditAction>(AuditAction)),
      whom: yup.string().required(),
      date: yup.mixed().required(),
      postponedDate: yup.string().nullable()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = Audit.schema.validateSync(obj)

    this.action = validObj.action
    this.whom = validObj.whom
    this.date = SpaDateTime.fromString(validObj.date)
    this.postponedDate = validObj.postponedDate
  }

  static copy(source: Audit): Audit {
    return new Audit(source)
  }

  static of(
    action: AuditAction,
    whom: string,
    date: SpaDateTime,
    postponedDate: string | undefined | null
  ): Audit {
    return Audit.copy({
      action,
      whom,
      date,
      postponedDate
    })
  }
}
