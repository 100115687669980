import { BaseSerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'

import { contentType } from '@local/payroll/shared/types/content'
import { EmployeeEarningFactory } from '@local/payroll/shared/models/factories'
import {
  PayrollEmployee,
  EarningCode
} from '@local/payroll/shared/types/graphDeprecated'

export interface AddBulkEarningModel {
  employeeUuids: string[]
  earningCode: EarningCode
  checkCode: {
    label: string
    value: string
  }
  /**represented as double */
  hours?: number
  /**represented as double */
  amount?: number
  isSecondWeek: boolean
}

/**
 * 12/21/20 - Matthew Lee
 * In regards to hard coded pay stub: bulk earnings are currently always applied to the first pay stub
 */
export class BulkEarningSerializer extends BaseSerializer<AddBulkEarningModel> {
  serialize(earning: AddBulkEarningModel) {
    const model = {
      employeeUuids: earning.employeeUuids,
      checkCodeUuid: earning.checkCode.value,
      earningCodeUuid: earning.earningCode.value,
      hours: earning.hours?.toFixed(2),
      amount: earning.amount?.toFixed(2),
      paystub: 1,
      week: earning.isSecondWeek ? 2 : 1
    }

    return model
  }
}

export const AddBulkEarningResourceEcPayroll = new Resource<
  AddBulkEarningModel,
  PayrollAction
>(
  new BulkEarningSerializer(),
  'payroll/:payroll_id/employees/earnings',
  contentType
)

//#region mirage
withMirageServerDo((server, faker) => {
  /**
   * I don't like this is duplicated, but I also don't want to share it
   * because then it would be included in the final output?
   */
  const BuildMockEarning = (earning: AddBulkEarningModel, _paystub: number) => {
    return EmployeeEarningFactory.copy({
      id: faker.random.number() + '',
      uuid: faker.random.uuid(),
      name: faker.helpers.randomize(['Regular', 'Overtime']),
      location: faker.helpers.randomize([
        'Streeterville',
        'River North',
        'Wicker Park',
        'Old Town',
        'Lincoln Park'
      ]),
      job: faker.helpers.randomize([
        'server',
        'bartender',
        'chef',
        'owner',
        'hostess',
        'cook',
        'line-cook'
      ]),
      rate: 0,
      baseRate: 0,
      hours: earning?.hours || 0,
      checkCode: {
        label: earning.checkCode.label.toString(),
        value: earning.checkCode.value
      },
      earningCode: earning.earningCode,
      isFirstWeek: earning.isSecondWeek,
      amount: earning?.amount || 0
    })
  }

  server.post(
    AddBulkEarningResourceEcPayroll.endpointTemplate(),
    function (schema, request) {
      const model: AddBulkEarningModel = JSON.parse(request.requestBody)

      model.employeeUuids.forEach((e) => {
        const employee: PayrollEmployee = schema.db.payrollEmployee.findBy({
          employeeUuid: e
        })
        //This add to the first paystub is intentional, bulk deductions are expected to be added to the first paystub
        employee.paystubs[0].earnings.push(BuildMockEarning(model, 1))
      })

      return PayrollAction.of(1)
    }
  )
})
//#endregion
