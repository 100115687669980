import * as React from 'react'

/**
 * Waits for an arbitrary function to resolve to true on an interval.
 * This should only be used when you have an external dependency that you
 * do not control the state of.
 *
 * EX: This initial usage of this was to watch the state of a variable
 * in esx-web to determine if a button should be shown. There is another
 * system changing the value of `window.gp.vm.step()` behind the scenes
 * and we want to watch the result of that function to make a change in
 * the application.
 *
 * @param testFn a function which will eventually return true
 * @param intervalDurationMs the interval duration in milliseconds
 */
export const useWaitForUIState = (
  testFn: () => boolean,
  intervalDurationMs = 500
) => {
  const [isCompleted, setIsCompleted] = React.useState(false)
  const [isErrored, setIsErrored] = React.useState(false)

  React.useEffect(() => {
    try {
      const result = testFn()
      if (result) {
        setIsCompleted(true)
        return
      }
    } catch {
      setIsErrored(true)
      return
    }

    const interval = setInterval(() => {
      try {
        const result = testFn()
        if (result) {
          setIsCompleted(true)
        }
      } catch (ex) {
        setIsErrored(true)
      }
    }, intervalDurationMs)

    if (isCompleted || isErrored) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [isCompleted, isErrored, testFn, intervalDurationMs])

  return [isCompleted, isErrored]
}
