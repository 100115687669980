import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { BaseSerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import {
  EmployeeDeductionFactory,
  PayrollEmployeeFactory
} from '@local/payroll/shared/models/factories'

import { BulkEmployeeDeduction } from '@local/payroll/experience/details/models'
import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'

class AddBulkDeductionSerializer extends BaseSerializer<
  BulkEmployeeDeduction,
  PayrollAction<PayrollEmployee[]>
> {
  deserialize(model: any) {
    const result: PayrollAction<PayrollEmployee[]> = {
      ...model,
      result: model.result.map((e: unknown) => PayrollEmployeeFactory.create(e))
    }

    return result
  }
}

// This resource hits esx-web
export const AddBulkDeductionResource = new Resource<
  BulkEmployeeDeduction,
  PayrollAction<PayrollEmployee[]>
>(
  new AddBulkDeductionSerializer(),
  ':client/payroll/employees/deductions/:payroll_id'
)

export enum EnumBulkAddDeductionType {
  AllEmployees = 1,
  AllSalaried = 2,
  AllHourly = 3,
  SelectedEmployees = 4
}

//#region Mirage support
withMirageServerDo((server, faker) => {
  const BuildMockDeduction = (_paystub: number) => {
    return EmployeeDeductionFactory.copy({
      id: faker.random.number() + '',
      deductionCodeId: faker.random.number() + '',
      name: faker.helpers.randomize(['Federal', 'State']),
      amount: faker.random.number(10),
      isPercentage: faker.random.boolean(),
      isReadOnly: Math.random() <= 0.2
    })
  }

  server.post(
    AddBulkDeductionResource.endpointTemplate(),
    function (schema, request) {
      const model: BulkEmployeeDeduction = JSON.parse(request.requestBody)

      model.employeeIds.forEach((id) => {
        const employee: PayrollEmployee = schema.db.payrollEmployee.findBy({
          employeeId: id
        })
        //This add to the first paystub is intentional, bulk deductions are expected to be added to the first paystub
        employee.paystubs[0].deductions.push(BuildMockDeduction(1))
      })

      return PayrollAction.of(schema.db.payrollEmployee)
    },
    { timing: 1000 }
  )
})
