import {
  CheckCodeFactory,
  EarningCodeFactory,
  EmployeeDeductionFactory,
  EmployeeEarningFactory,
  PayrollEmployeeFactory,
  PayCheckFactory
} from '@local/payroll/shared/models/factories'

import {
  AddEmployeeDeduction,
  UpdateEmployeeDeduction
} from '@local/payroll/experience/details/models'

import { FactorySerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { AddDeductionResource, UpdateDeductionResource } from './deductions'
import {
  PayCheck,
  PayCheckDeduction,
  PayCheckEarning,
  PayrollEmployee,
  CheckCode,
  EarningCode
} from '@local/payroll/shared/types/graphDeprecated'

export const PayrollEmployeeSerializer = new FactorySerializer(
  PayrollEmployeeFactory.create
)

// This resource hits esx-web
export const EmployeeResource = new Resource<PayrollEmployee>(
  PayrollEmployeeSerializer,
  ':client/payroll/employees/list/:payroll_id'
)

// This resource hits esx-web
export const SingleEmployeeResource = new Resource<PayrollEmployee>(
  PayrollEmployeeSerializer,
  ':client/payroll/:payroll_id/employees/:id/single'
)

//#region Mirage support
withMirageServerDo((server, faker) => {
  //#region Check Codes

  server.db.createCollection('checkCode', [
    CheckCodeFactory.copy({ value: faker.random.uuid(), label: 'Bonus' }),
    CheckCodeFactory.copy({ value: faker.random.uuid(), label: 'Weekly' }),
    CheckCodeFactory.copy({ value: faker.random.uuid(), label: 'Bi-weekly' }),
    CheckCodeFactory.copy({
      value: faker.random.uuid(),
      label: 'Semi-monthly'
    }),
    CheckCodeFactory.copy({ value: faker.random.uuid(), label: 'Monthly' }),
    CheckCodeFactory.copy({ value: faker.random.uuid(), label: 'Quarterly' }),
    CheckCodeFactory.copy({
      value: faker.random.uuid(),
      label: 'Semi-Annually'
    }),
    CheckCodeFactory.copy({ value: faker.random.uuid(), label: 'Annually' })
  ])

  //#endregion

  //#region Earning Codes
  server.db.createCollection('earningCode', [
    EarningCodeFactory.copy({
      value: faker.random.number() + '',
      label: 'Bonus',
      isHourly: false,
      isOT: false
    }),
    EarningCodeFactory.copy({
      value: faker.random.number() + '',
      label: 'Regular',
      isHourly: true,
      isOT: false
    }),
    EarningCodeFactory.copy({
      value: faker.random.number() + '',
      label: 'Salary',
      isHourly: false,
      isOT: false
    }),
    EarningCodeFactory.copy({
      value: faker.random.number() + '',
      label: 'Overtime',
      isHourly: true,
      isOT: true
    }),
    EarningCodeFactory.copy({
      value: faker.random.number() + '',
      label: 'Special',
      isHourly: true,
      isOT: false
    }),
    EarningCodeFactory.copy({
      value: faker.random.number() + '',
      label: 'Secret',
      isHourly: true,
      isOT: false
    })
  ])

  //#endregion

  //#region Employee, Earning, Deduction
  const BuildMockEarning = (
    checkCode: CheckCode //check codes are tied to the paystub not the earning
  ): PayCheckEarning => {
    const earningCodes = server.db.earningCode.where({})
    const earningCode: EarningCode = faker.helpers.randomize(earningCodes)
    const rate = faker.random.number({ min: 13, max: 25 })
    const hours = faker.random.number({ min: 40, max: 240 }) / 20

    return EmployeeEarningFactory.copy({
      id: faker.random.number() + '',
      uuid: faker.random.uuid(),
      name: earningCode.label,
      location: faker.helpers.randomize([
        'Streeterville',
        'River North',
        'Wicker Park',
        'Old Town',
        'Lincoln Park',
        'The Quick Brown Fox Is In Chicago'
      ]),
      job: faker.helpers.randomize([
        'server',
        'bartender',
        'chef',
        'owner',
        'hostess',
        'cook',
        'line-cook'
      ]),
      rate: rate,
      baseRate: rate,
      hours: hours,
      checkCode: checkCode,
      earningCode: earningCode,
      isFirstWeek: faker.helpers.randomize([false, true]),
      amount: rate * hours
    })
  }

  const BuildMockDeduction = (): PayCheckDeduction => {
    return EmployeeDeductionFactory.copy({
      id: faker.random.number() + '',
      deductionCodeId: faker.random.number() + '',
      name: faker.helpers.randomize([
        '401k',
        'Child Support',
        'Garnishment',
        'Roth',
        'HSA',
        'Medical',
        'Dental',
        'Vision',
        'Unicorn'
      ]),
      amount: faker.random.number(10),
      isPercentage: faker.random.boolean(),
      isReadOnly: Math.random() <= 0.2
    })
  }

  const BuildMockPayStub = (number: number): PayCheck => {
    const isReadOnly = Math.random() <= 0.2
    let isVoid = false
    let isManual = false
    if (isReadOnly) {
      isVoid = faker.random.boolean()
      isManual = !isVoid
    }

    const checkCode: CheckCode = faker.helpers.randomize(
      server.db.checkCode.where({})
    )

    return PayCheckFactory.copy({
      id: faker.random.alphaNumeric(5),
      paidByCheck: faker.random.boolean(),
      number,
      earnings: [BuildMockEarning(checkCode), BuildMockEarning(checkCode)],
      deductions: [BuildMockDeduction(), BuildMockDeduction()],
      isReadOnly,
      isVoid,
      isManual,
      checkCode
    })
  }

  const BuildEmployee = (id: string): PayrollEmployee => {
    const name = `${faker.name.lastName()}, ${faker.name.firstName()}`
    return PayrollEmployeeFactory.copy({
      id: faker.random.number() + '',
      name: `${name}`,
      employeeNumber: `E${id}`,
      employeeId: faker.random.number() + '',
      employeeUuid: faker.random.uuid(),
      payperiodUuid: faker.random.uuid(),
      paystubs: faker.random.arrayElement([
        [BuildMockPayStub(1)],
        [BuildMockPayStub(1), BuildMockPayStub(2)],
        [BuildMockPayStub(1), BuildMockPayStub(2), BuildMockPayStub(3)]
      ]),
      payChecks: faker.random.arrayElement([
        [BuildMockPayStub(1)],
        [BuildMockPayStub(1), BuildMockPayStub(2)],
        [BuildMockPayStub(1), BuildMockPayStub(2), BuildMockPayStub(3)]
      ])
    })
  }

  const payrollEmployeesList: PayrollEmployee[] = []

  for (let i = 0; i < 20; i++) {
    payrollEmployeesList.push(BuildEmployee(`${i}`))
  }

  server.db.createCollection('payrollEmployee', payrollEmployeesList)

  //#endregion

  server.get(
    EmployeeResource.endpointTemplate(),
    ({ db }) => db.payrollEmployee
  )

  // TODO: These functions should update the mirage db state.
  server.post(
    AddDeductionResource.endpointTemplate(),
    function ({ db }, { requestBody, params }) {
      const deduction: AddEmployeeDeduction = JSON.parse(requestBody)

      const employee: PayrollEmployee = db.payrollEmployee.findBy({
        employeeId: params.employee_id
      })

      const paystub = employee.paystubs.find(
        (ps) => ps.number === deduction.paystub
      )

      if (paystub) {
        paystub.deductions.push({
          ...deduction,
          id: faker.random.alphaNumeric(1),
          isReadOnly: false
        })
      } else {
        console.error(`unable to add deduction: ${JSON.stringify(deduction)}`)
      }

      // TODO: This should work, but doesn't unless I change the employee to new PayrollEmployee(employee)
      // The miragejs does not return the objects, but actually copies of the objects, so
      // we have to construct a real object when we use data from a dbCollection:
      return PayrollAction.of(employee)
    },
    { timing: 2000 }
  )
  server.get(
    SingleEmployeeResource.endpointTemplate(),
    function ({ db }, { queryParams: { id } }) {
      return db.payrollEmployee.firstOrCreate(
        { employeeUuid: id },
        BuildEmployee(id)
      )
    },
    { timing: 2000 }
  )
  server.post(
    UpdateDeductionResource.endpointTemplate(),
    function (schema, request) {
      const { deduction_id } = request.params
      const deduction: UpdateEmployeeDeduction = JSON.parse(request.requestBody)

      const employee: PayrollEmployee = schema.db.payrollEmployee.findBy({
        employeeId: request.params.employee_id
      })

      employee.paystubs.forEach((ps) => {
        const index = ps.deductions.findIndex(
          (x) => x.id === request.params.deduction_id
        )

        ps.deductions[index] = EmployeeDeductionFactory.copy({
          id: deduction_id,
          deductionCodeId: deduction.deductionCodeId,
          name: deduction.name,
          amount: deduction.amount,
          isPercentage: deduction.isPercentage,
          isReadOnly: false
        })
      })

      return PayrollAction.of(employee)
    },
    { timing: 2000 }
  )
})
//#endregion
