import * as React from 'react'
import { CookiesProvider } from 'react-cookie'
import { AppContainer } from './AppContainer'
import {
  CurrentUserProvider,
  I18nProvider,
  ConfigProvider,
  TrackingProvider,
  EnvironmentProvider,
  WindowProvider
} from './contexts'

export type ApplicationProps = {
  name: string
}

export const withEcApplication =
  (
    Application: React.FC,
    testId: string,
    Fallback: NonNullable<React.ReactNode> = <div></div>,
    tailwindVersion = 'data-pui-2'
  ) =>
  (props: ApplicationProps) => {
    const { name } = props

    return (
      <React.Suspense fallback={Fallback}>
        <AppContainer
          testId={testId}
          application={name}
          tailwindVersion={tailwindVersion}
        >
          <WindowProvider>
            <EnvironmentProvider>
              <I18nProvider>
                <ConfigProvider>
                  <CurrentUserProvider>
                    <TrackingProvider>
                      <CookiesProvider>
                        <Application />
                      </CookiesProvider>
                    </TrackingProvider>
                  </CurrentUserProvider>
                </ConfigProvider>
              </I18nProvider>
            </EnvironmentProvider>
          </WindowProvider>
        </AppContainer>
      </React.Suspense>
    )
  }
