import { FactorySerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { AccountingSummary } from '@local/payroll/experience/details/models'

const AccountingSummarySerializer = new FactorySerializer(
  (payload) => new AccountingSummary(payload)
)

// resource hits esx-web
export const AccountingSummaryResource = new Resource<AccountingSummary>(
  AccountingSummarySerializer,
  ':client/payroll/details/accountingSummary/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server) => {
  server.db.createCollection('payrollAccountingSummary', [])
  server.get(
    AccountingSummaryResource.endpointTemplate(),
    function (schema, _request) {
      return schema.db.payrollAccountingSummary.firstOrCreate(
        AccountingSummaryResource.serializer.serialize(
          AccountingSummary.of(4320.25, 4200, 1000, 2500, 1000, 500, 500, 6302)
        )
      )
    },
    { timing: 4000 }
  )
})
//#endregion
