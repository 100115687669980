import { FactorySerializer, Resource } from '@local/rest-data'
import { contentType } from '@local/payroll/shared/types/content'
import { SpaDateTime, withMirageServerDo } from '@local/shared-services'
import {
  AuditEmployee,
  AuditUser,
  PaperCheckAudit
} from '@local/payroll/experience/details/models'

const PaperCheckAuditSerializer = new FactorySerializer(
  (payload) => new PaperCheckAudit(payload)
)

export const PaperCheckAuditResource = new Resource<PaperCheckAudit>(
  PaperCheckAuditSerializer,
  ':client/payroll/:payroll_uuid/checksPdfs/audit',
  contentType
)

export const PaperCheckAuditResourceEcPayroll = new Resource<PaperCheckAudit>(
  PaperCheckAuditSerializer,
  'payroll/:payroll_uuid/checksPdfs/audit',
  contentType
)

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.get(PaperCheckAuditResource.endpointTemplate(), function (_schema) {
    return [
      PaperCheckAudit.copy({
        id: faker.random.number() + '',
        createdAt: SpaDateTime.fromRecent(),
        user: AuditUser.of(faker.random.uuid(), faker.internet.userName()),
        employee: AuditEmployee.copy({
          uuid: faker.random.uuid(),
          firstName: faker.name.firstName(),
          lastName: faker.name.lastName(),
          employeeNumber: faker.random.number() + ''
        })
      }),
      PaperCheckAudit.copy({
        id: faker.random.number() + '',
        createdAt: SpaDateTime.fromRecent(),
        user: AuditUser.of(faker.random.uuid(), faker.internet.userName()),
        employee: new AuditEmployee(undefined)
      }),
      PaperCheckAudit.copy({
        id: faker.random.number() + '',
        createdAt: SpaDateTime.fromRecent(),
        user: AuditUser.of(faker.random.uuid(), faker.internet.userName()),
        employee: AuditEmployee.copy({
          uuid: faker.random.uuid(),
          firstName: faker.name.firstName(),
          lastName: faker.name.lastName(),
          employeeNumber: faker.random.number() + ''
        })
      })
    ]
  })
})
//#endregion
