import { FactorySerializer, Resource } from '@local/rest-data'
import {
  withMirageServerDo,
  SpaDateTime,
  SpaDate
} from '@local/shared-services'
import { PayrollSummary } from '@local/payroll/experience/details/models'

const Serializer = new FactorySerializer(
  (payload) => new PayrollSummary(payload)
)

// resource hits esx-web
export const PayrollSummaryResource = new Resource<PayrollSummary>(
  Serializer,
  ':client/payroll/details/payrollSummary/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server, _faker) => {
  server.db.createCollection('payrollSummary', [])
  server.get(
    PayrollSummaryResource.endpointTemplate(),
    function (schema, _request) {
      return schema.db.payrollEmployeeSummary.firstOrCreate(
        PayrollSummaryResource.serializer.serialize(
          PayrollSummary.of(
            '1',
            0,
            SpaDate.fromToday().plus(2),
            SpaDateTime.fromPostingDeadline()
          )
        )
      )
    },
    { timing: 4000 }
  )
  // triggering imbalance error for id 2222
  server.get(
    ':client/payroll/details/payrollSummary/2222',
    function (schema, _request) {
      return schema.db.payrollEmployeeSummary.firstOrCreate(
        PayrollSummaryResource.serializer.serialize(
          PayrollSummary.of(
            '1',
            2.45,
            SpaDate.fromToday().plus(2),
            SpaDateTime.fromPostingDeadline()
          )
        )
      )
    },
    { timing: 4000 }
  )
})
//#endregion
