import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { PayCheckEarning } from '@local/payroll/shared/types/graphDeprecated'
import { DeleteEmployeeEarningResource } from './resource'
import { PayrollEmployeeFactory } from '@local/payroll/shared/models/factories'

export const DeleteEarningMutation = async ({
  client,
  payroll_id,
  employee_id,
  earning
}: {
  client: string
  payroll_id: string
  employee_id: string
  earning: PayCheckEarning
}) => {
  const response = await DeleteEmployeeEarningResource.getStore({
    client,
    payroll_id,
    employee_id,
    earning_id: earning.id
  }).deleteRecord(PayrollAction.of())

  if (response.errors) {
    throw PayrollAction.of(earning, response.errors)
  }

  return PayrollEmployeeFactory.create(response.result)
}
