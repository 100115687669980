/**
 * Creates a type guard that compares a value to the predefined value.
 */
export const fromValue =
  <T>(
    value: T,
    equals: (a: T, b: T | null | undefined) => boolean = (a, b) => a === b
  ) =>
  (obj: any): obj is T =>
    equals(value, obj)

/**
 * Creates a type guard that compares a key on an object to the predefined value.
 */
export const fromKeyValue =
  <T, K extends keyof T>(
    key: K,
    value: T[K],
    equals: (a: T[K], b: T[K] | null | undefined) => boolean = (a, b) => a === b
  ) =>
  (obj: any): obj is T =>
    equals(value, obj?.[key])

export const fromKeys =
  <TObject extends Record<any, any>>(keys: Array<keyof TObject>) =>
  (obj: unknown): obj is TObject =>
    typeof obj === 'object' &&
    obj != null &&
    keys.every((key) => obj.hasOwnProperty(key))

export const TypeGuards = {
  fromKeyValue,
  fromValue,
  fromKeys
}
