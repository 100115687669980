import { Resource } from '@local/rest-data'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { contentType } from '@local/payroll/shared/types/content'
import { withMirageServerDo } from '@local/shared-services'
import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'

export const PayByEmployeeResource = new Resource<PayrollAction>(
  new PayrollActionSerializer(),
  ':client/payroll/:payroll_id/employees/:employee_id/paybycheck/:paycheckNumber',
  contentType
)
export const PayByEmployeeResourceEcPayroll = new Resource<PayrollAction>(
  new PayrollActionSerializer(),
  'payroll/:payroll_id/employees/:employee_id/paybycheck/:paycheckNumber',
  contentType
)

withMirageServerDo((server, _faker) => {
  server.put(
    PayByEmployeeResource.endpointTemplate(),
    function (server, request) {
      //id is the paystub id
      const { employee_id, payby, id } = request.params

      const employee: PayrollEmployee = server.db.payrollEmployee.findBy({
        employeeUuid: employee_id
      })

      const update = {
        paidByCheck: payby === 'paybydeposit'
      }

      const index = employee.paystubs.findIndex((ps) => ps.id === id)

      employee.paystubs[index] = {
        ...employee.paystubs[index],
        ...update
      }

      return PayrollAction.of(1, [], '')
    }
  )
})
