import * as React from 'react'
import { AlertModal } from '@local/payroll/shared/components/modals'
import { ModalHooksFor } from '@local/payroll/experience/details/stores/ModalStore'
import { Button } from '@toasttab/buffet-pui-buttons'

interface AppConfirmProps<T = any> {
  labels: {
    header: string
    body: string
    loading?: {
      text?: string
    }
    buttons: { confirm: string; cancel: string }
  }
  onCancel?: () => void
  onConfirm: () => Promise<T>
}

const { useOpenModal, useModalContext, useIsModalOpen, useCloseModal } =
  ModalHooksFor<AppConfirmProps>('Confirmation')

export const useAppConfirm = () => {
  const open = useOpenModal()
  const close = useCloseModal()
  const isOpen = useIsModalOpen()

  return {
    open,
    close,
    isOpen
  }
}

export const AppConfirmModal = () => {
  const context = useModalContext()
  const isOpen = useIsModalOpen()
  const close = useCloseModal()

  if (!isOpen) return null

  const handleCancel = () => {
    // eslint-disable-next-line no-unused-expressions
    context.onCancel?.()
    close()
  }

  const handleConfirm = async () => {
    // eslint-disable-next-line no-unused-expressions
    context.onConfirm?.()
    close()
  }

  const { labels } = context

  return (
    <AlertModal title={labels.header} isOpen={true}>
      <AlertModal.Body>
        <p className='type-default text-secondary'>{labels.body}</p>
      </AlertModal.Body>
      <AlertModal.Footer>
        <Button
          variant='text-link'
          onClick={handleCancel}
          testId={`${labels.buttons.cancel}-cancel`}
          className='mr-3'
        >
          {labels.buttons.cancel}
        </Button>
        <Button
          onClick={handleConfirm}
          testId={`${labels.buttons.confirm}-confirmed`}
        >
          {labels.buttons.confirm}
        </Button>
      </AlertModal.Footer>
    </AlertModal>
  )
}
