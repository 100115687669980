import { useSearchParams } from 'react-router-dom'

type TKeys = 'id' | 'step'

/**
 *
 * @param key the key from the query string you want to access
 * @param defaultValue the value that will be returned if the key does not exist
 * @returns a string or the default you passed in
 */
export const useQueryParams = <TDefault>(
  key: TKeys,
  defaultValue: TDefault
): string | TDefault => {
  const [params] = useSearchParams()

  const value = params.get(key)

  return value === null ? defaultValue : value
}
