import { Server } from 'miragejs'
import type Faker from 'faker'

/*
  faker is included via the dummy and will "just be there" in development mode only
*/

type MirageOperation = (arg0: Server, faker: Faker.FakerStatic) => void

export const withMirageServerDo = (callback: MirageOperation) => {
  if (process.env.NODE_ENV !== 'production') {
    //@ts-ignore
    const server: Server = window.server
    if (server) {
      //@ts-ignore
      callback(server, window.faker)
    }
  }
}
