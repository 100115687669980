import * as yup from 'yup'

export class EarningsBucket {
  readonly lowerBound: number
  readonly count: number

  private static readonly schema = yup
    .object({
      lowerBound: yup.number().required(),
      count: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = EarningsBucket.schema.validateSync(obj)

    this.lowerBound = validObj.lowerBound
    this.count = validObj.count
  }

  static copy(source: EarningsBucket): EarningsBucket {
    return new EarningsBucket(source)
  }

  static of(lowerBound: number, count: number): EarningsBucket {
    return EarningsBucket.copy({
      lowerBound,
      count
    })
  }
}
