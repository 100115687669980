import { withMirageServerDo } from '@local/shared-services'

import { RestResource } from '@local/rest-data'
import { contentType } from '@local/payroll/shared/types/content'
import { EarningCode } from '@local/payroll/shared/types/graphDeprecated'

export const EarningCodeResource = new RestResource<EarningCode>({
  resource: ':client/payroll/config/earningcodes',
  contentType
})

export const EarningCodeResourceEcPayroll = new RestResource<EarningCode>({
  resource: 'payroll/config/earningcodes',
  contentType
})

//#region Mirage support
withMirageServerDo((server, _faker) => {
  server.get(
    EarningCodeResource.endpointTemplate(),
    (schema) => schema.db.earningCode
  )
})
// #endregion
