import * as yup from 'yup'
import { SpaDateTime, SpaDate } from '@local/shared-services'

export class PayrollSummary {
  readonly id: string
  readonly glBalance: number
  readonly checkDate: SpaDate
  readonly payrollDate: SpaDateTime | undefined | null

  private static readonly schema = yup
    .object({
      id: yup.string().nullable(),
      glBalance: yup.number().required(),
      checkDate: yup.mixed().required(),
      payrollDate: yup.mixed().nullable()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = PayrollSummary.schema.validateSync(obj)

    this.id = validObj.id || '1'
    this.glBalance = validObj.glBalance
    this.checkDate = SpaDate.fromString(validObj.checkDate)
    this.payrollDate = SpaDateTime.fromStringNullable(validObj.payrollDate)
  }

  static copy(source: PayrollSummary): PayrollSummary {
    return new PayrollSummary(source)
  }

  static of(
    id: string,
    glBalance: number,
    checkDate: SpaDate,
    payrollDate: SpaDateTime
  ): PayrollSummary {
    return PayrollSummary.copy({
      id,
      glBalance,
      checkDate,
      payrollDate
    })
  }
}
