import * as yup from 'yup'
import { LevelSelection } from '../../levels/models'

export const EmployeePositionFactory = {
  create(obj: unknown) {
    const position = yup
      .object({
        uuid: yup.string().required(),
        isPrimary: yup.boolean().required(),
        levels: yup.array().defined()
      })
      .defined()
      .validateSync(obj)

    return {
      ...position,
      levels: position.levels.map((level) => new LevelSelection(level))
    }
  }
}
