import * as React from 'react'
import { CoreConfig, ConfiguredWindow } from '@local/ec-app'
import {
  useTopicSubscription,
  CONFIGURATION_CHANGED,
  useTopicPublisher
} from '@local/publisher'
import { useWindowProvider } from './window'

export const ConfigContext = React.createContext<CoreConfig>(
  null as unknown as CoreConfig
)

const currentConfigResolver = (window: Window): CoreConfig => {
  return (window as ConfiguredWindow).Toast
}

export const ConfigProvider = ({
  children
}: {
  children?: React.ReactNode
}) => {
  const window = useWindowProvider()
  const [config, setConfig] = React.useState(currentConfigResolver(window))
  const onConfigChanged = React.useCallback(
    () => setConfig({ ...currentConfigResolver(window) }),
    [setConfig, window]
  )

  // We want to update this context when configuration changes from any outside source.
  useTopicSubscription(CONFIGURATION_CHANGED, onConfigChanged)

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        refreshConfig: () => currentConfigResolver(window)
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

/**
 * Provides a function for partially updating the global config (Toast) object.
 * Note, this handles notifying other applications of the changes so they can update accordingly.
 */
export const useUpdateConfig = () => {
  const window = useWindowProvider() as ConfiguredWindow
  const { publish } = useTopicPublisher()

  return React.useCallback(
    (config: Partial<CoreConfig>) => {
      Object.assign(window.Toast, config)
      publish(CONFIGURATION_CHANGED)
    },
    [window, publish]
  )
}
