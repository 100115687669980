import * as React from 'react'
import { atom, useRecoilState, useResetRecoilState } from 'recoil'

import { Alert } from '@toasttab/buffet-pui-alerts'

import { AppRuntimeException } from '../models/AppRuntimeException'
import { useTranslation } from 'react-i18next'

type PageError = {
  error?: AppRuntimeException
  translationsNs?: string
}

const pageErrorState = atom<PageError>({
  key: 'payroll-error-boundary-state',
  default: {
    error: undefined,
    translationsNs: 'errors'
  }
})

export const usePageError = (clearOnUnmount = true) => {
  const [state, setError] = useRecoilState(pageErrorState)
  const clearError = useResetRecoilState(pageErrorState)

  const hasError = state.error !== undefined

  React.useEffect(() => {
    return () => {
      if (clearOnUnmount) clearError()
    }
  }, [clearOnUnmount, clearError])

  return {
    ...state,
    messageKey: `${state.translationsNs}.${
      state.error?.messageKey ?? 'default'
    }`,
    hasError,
    setError: React.useCallback(
      (error: AppRuntimeException, translationsNs = 'error') =>
        setError({ error, translationsNs }),
      [setError]
    ),
    clearError
  }
}

export const PageError: React.FC = () => {
  const { messageKey, hasError } = usePageError()
  const { t } = useTranslation('payroll')
  if (!hasError) {
    return null
  }
  return (
    <Alert variant='error' className='mb-4' testId={'page-error'}>
      {t(`${messageKey}`)}
    </Alert>
  )
}
