// This is a one off type just for the highlights and exceptions view.
// We are not planning to use web longterm, so do not re-use this type anywhere else.
export type NoteworthyEmployeeDetail = {
  employeeNumber: string
  uuid: string
  firstName: string
  lastName: string
}

export class NoteworthySummary {
  static default = new NoteworthySummary('', '', [])

  constructor(
    readonly id: string,
    readonly metric: string,
    readonly employees: Array<NoteworthyEmployeeDetail>
  ) {}
}
