import { BaseSerializer, Resource } from '@local/rest-data'
import { LabelledValue } from '@local/payroll/experience/details/models'
import { PayrollRouteParams } from '@local/payroll/shared/types/params'
import {
  SpaDateTime,
  SpaDate,
  withMirageServerDo
} from '@local/shared-services'
import { PayPeriodFactory } from './factories/PayPeriodFactory'
import {
  CheckCode,
  DeliveryMethod,
  PayPeriod,
  PostDeadline,
  PreviousProviderPayrollType
} from '@local/payroll/gen/queries'
import {
  createCheckPackages,
  createMessage
} from '@local/payroll/test-helpers/mockModels'
import { buildTimesheetsUrl } from '../utils/urls'

export enum PayrollStatus {
  unknown = 0, // ??
  created = 1, // not ready to review
  opened = 2, // not ready to review
  scheduled = 3, // calculation in progress
  calculated = 4, // calculations complete, ready to post
  verified = 5, // ?? => not used
  posted = 6, // post completed
  generated_ach = 7, // ACH Files generated for ee to be paid
  scheduling = 98, // payroll service pulls the payroll but it doesn't start processing it just yet. intermediate status between 3 and 4
  processing = 99, // payroll service start processing it, intermediate status between 3 and 4
  editing = 888, // undo payroll, clicked edit but not on opened status yet.
  posting = 999, // transition status before final posted
  import = 1000 // transition status while importing timesheets
}

export class Serializer extends BaseSerializer<PayPeriod> {
  deserialize(payload: any) {
    return PayPeriodFactory.deserialize(payload)
  }
}

// Resource hits esx-web
export const PayrollResource = new Resource<
  PayPeriod,
  PayPeriod,
  PayrollRouteParams
>(new Serializer(), ':client/payroll/details/payrollDetail/:payroll_id')

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.db.createCollection('payrolls')

  //#region Check Codes
  server.db.createCollection('checkCode', [
    LabelledValue.of(faker.random.number() + '', 'Bonus'),
    LabelledValue.of(faker.random.number() + '', 'Weekly'),
    LabelledValue.of(faker.random.number() + '', 'Bi-weekly'),
    LabelledValue.of(faker.random.number() + '', 'Semi-monthly'),
    LabelledValue.of(faker.random.number() + '', 'Monthly'),
    LabelledValue.of(faker.random.number() + '', 'Quarterly'),
    LabelledValue.of(faker.random.number() + '', 'Semi-Annually'),
    LabelledValue.of(faker.random.number() + '', 'Annually')
  ])

  let count = 0

  const startDate = SpaDate.fromToday()
  const endDate = startDate.plus(7)
  const checkDate = endDate.plus(4)
  const checkDateOverride = checkDate.plus(2)
  const postDeadline = 1
  const postDeadlineText = PostDeadline.OneDayBefore
  const dueDate = endDate.minus(1)
  const previousPayPeriodStartDate = startDate.minus(7)
  const previousPayPeriodEndDate = endDate.minus(7)

  server.get(
    PayrollResource.endpointTemplate(),
    function (schema, request) {
      const { client, payroll_id } = request.params

      let payroll = schema.db.payrolls.findBy({ id: payroll_id })
      let status = payroll?.status ?? PayrollStatus.unknown

      switch (request.params.payroll_id) {
        case '1111':
        case 'calculating':
        case 'scheduled':
          status = PayrollStatus.scheduled
          break
        case 'calculated':
          status = PayrollStatus.calculated
          break
        case '1235':
        case 'posted':
          status = PayrollStatus.posted
          break
        case 'payownway':
          status = PayrollStatus.posted
          break
        case 'opened':
          status = PayrollStatus.opened
          break
        case 'created':
          status = PayrollStatus.created
          break
        default:
          status =
            !status || status === PayrollStatus.unknown
              ? PayrollStatus.calculated
              : status
          break
      }

      if (!payroll) {
        const checkCodes = server.db.checkCode.where({})
        const checkCode = faker.helpers.randomize<CheckCode>(checkCodes)
        const uuid = faker.random.uuid()
        payroll = schema.db.payrolls.firstOrCreate(
          PayrollResource.serializer.serialize(
            PayPeriodFactory.copy({
              uuid,
              name: faker.company.companyName(),
              status: status,
              startDate,
              endDate,
              checkDate,
              checkDateOverride,
              dueDate,
              postDeadline,
              postDeadlineText,
              adjustmentUrl: `/${client}/payroll/employees/spreadsheet/${payroll_id}`,
              dashboardUrl: `/${client}/payroll/details/preview/${payroll_id}`,
              timesheetsImportUrl: `/mvc/${client}/company/payroll/timeimport/${payroll_id}`,
              timesheetsImportUrlAloha: `/mvc/${client}/company/payroll/alohalaborreport/${payroll_id}`,
              listUrl: `/${client}/payroll/payrolls/list`,
              reviewUrl: `/${client}/payroll/details/review/${payroll_id}`,
              employeesSpreadsheetUrl: `/${client}/payroll/employees/spreadsheet/${payroll_id}`,
              previewUrl: `/${client}/payroll/details/preview/${payroll_id}`,
              preflightUrl: `/${client}/payroll/details/preflight/${uuid}?id=${payroll_id}`,
              taxesImportUrl: `/${client}/payroll/taxes/import`,
              paperChecksSettingsUrl: `/${client}/payroll/checks-settings`,
              timesheetsUrl: buildTimesheetsUrl(client),
              checkCode: checkCode,
              forceLiveChecks: false,
              taxDebitDate: SpaDate.fromToday().plus(4),
              datePosted: SpaDate.fromToday(),
              isReadOnly:
                status !== PayrollStatus.opened &&
                status !== PayrollStatus.calculated,
              debitDate: SpaDate.fromToday().plus(4),
              deliveryMethod: DeliveryMethod.ToastPrinted,
              toastPrintedChecks: {
                totalChecks: 4,
                totalEmployees: 2
              },
              checkPackages: [createCheckPackages(), createCheckPackages()],
              checkCodeName: checkCode.label,
              checkCodeUuid: checkCode.value,
              messages: [createMessage()],
              // TODO: payroll date is an effectively unused value, it should be ignored
              payrollDate: SpaDateTime.fromPostingDeadline(),
              previousPayPeriodUuid: faker.random.uuid(),
              previousPayPeriodStartDate,
              previousPayPeriodEndDate,
              previousProviderPayrollType: PreviousProviderPayrollType.none
            })
          )
        )
      }
      if (status === PayrollStatus.scheduled) {
        schema.db.payrolls.update({
          ...payroll,
          status:
            count++ > 3 ? PayrollStatus.scheduled : PayrollStatus.calculated,
          dashboardUrl: `/${client}/payroll/details/preview/${payroll_id}`,
          adjustmentUrl: `/${client}/payroll/employees/spreadsheet/${payroll_id}`,
          listUrl: `/${client}/payroll/payrolls/list/${payroll_id}`,
          scheduleUrl: `/${client}/payroll/payrolls/schedule/${payroll_id}`,
          reviewUrl: `/${client}/payroll/details/review/${payroll_id}`,
          employeeSpreadsheetUrl: `/${client}/payroll/employees/spreadsheet/${payroll_id}`
        })
      }

      return payroll
    },
    { timing: 1000 }
  )
})
//#endregion
