import { FactorySerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { EmployeeSummary } from '@local/payroll/experience/details/models'

const EmployeeSummarySerializer = new FactorySerializer(
  (payload) => new EmployeeSummary(payload)
)

// resource hits esx-web
export const EmployeeSummaryResource = new Resource<EmployeeSummary>(
  EmployeeSummarySerializer,
  ':client/payroll/details/employeeSummary/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server) => {
  server.db.createCollection('payrollEmployeeSummary', [])
  server.get(
    EmployeeSummaryResource.endpointTemplate(),
    function (schema, _request) {
      return schema.db.payrollEmployeeSummary.firstOrCreate(
        EmployeeSummaryResource.serializer.serialize(
          EmployeeSummary.of(21, 24, 4, 20, 448, 480, 20, 4)
        )
      )
    },
    { timing: 4000 }
  )
})
//#endregion
