import { PayrollRouteParams } from '@local/payroll/shared/types/params'
import { useParams } from 'react-router-dom'
import { useQueryParams } from './useQueryParam'

// HACK: Legacy Payroll IDs are base64 encoded (int ID + salt)s; modern payroll IDs are UUIDs
// There are a lot of ways to detect the difference, but this is the simplest
export const isLegacyPayrollId = (id = '') => !id.includes('-')

export const useSecureIdQueryParam = (): string | undefined =>
  useQueryParams('id', undefined)

// TODO: Once we no longer have any legacy IDs, we can just call usePayrollParams universally
// and then switch from using payrollSecureId to payrollUuid (i.e. the only payroll ID that would be left in route params)

export const usePayrollParams = (): {
  client: string
  payrollUuid?: string
} => {
  const { client, payroll_id: payrollUuid } = useParams<PayrollRouteParams>()

  if (!client) throw new Error('Company code not found.')

  // client should always be present in the payroll app; but payroll uuid can be absent on some pages
  // leave it to the consumer to handle the type safety

  return {
    client,
    payrollUuid
  }
}

export const useMigrationParams = (): {
  client: string
  payrollSecureId: string
} => {
  // This helper should only be used in scenarios where the params are actually present,
  // since our entire codebase is embedded in routes with these params.
  const { client = '', payroll_id: payrollId = '' } =
    useParams<PayrollRouteParams>()
  const secureIdQueryParam = useSecureIdQueryParam()

  return {
    client,
    payrollSecureId: isLegacyPayrollId(payrollId)
      ? payrollId
      : // If it's not a legacy ID, then we guarantee the query param is present
        secureIdQueryParam!
  }
}
