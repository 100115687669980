import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { Resource } from '@local/rest-data'
import { AddEmployeeDeduction } from '@local/payroll/experience/details/models'

import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollEmployeeFactory } from '@local/payroll/shared/models/factories'
import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'

// This resource hits esx-web
export const AddDeductionResource = new Resource<
  AddEmployeeDeduction,
  PayrollAction<PayrollEmployee>
>(
  new PayrollActionSerializer(PayrollEmployeeFactory.create),
  ':client/payroll/:payroll_id/employees/:employee_id/deduction'
)
