import { EnumToValueArray } from '@local/payroll/shared/utils/enumHelper'
import * as yup from 'yup'

export enum EnumEntryType {
  Hourly = 0,
  Salary = 1
}

export class TimesheetEntry {
  readonly earning: string
  readonly type: EnumEntryType
  readonly hours: number
  readonly amount: number

  private static readonly schema = yup
    .object({
      earning: yup.string().required(),
      type: yup
        .number()
        .oneOf(EnumToValueArray<EnumEntryType>(EnumEntryType))
        .required(),
      hours: yup.number().required(),
      amount: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = TimesheetEntry.schema.validateSync(obj)

    this.earning = validObj.earning
    this.type = validObj.type
    this.hours = validObj.hours
    this.amount = validObj.amount
  }

  static copy(timesheetEntry: TimesheetEntry): TimesheetEntry {
    return new TimesheetEntry(timesheetEntry)
  }

  static of(
    earning: string,
    type: EnumEntryType,
    hours: number,
    amount: number
  ): TimesheetEntry {
    return TimesheetEntry.copy({
      earning,
      type,
      hours,
      amount
    })
  }
}

export class TimesheetSummary {
  readonly id: string
  readonly approvedTimesheets: number
  readonly unapprovedTimesheets: number
  readonly entries: TimesheetEntry[]

  private static readonly schema = yup
    .object({
      id: yup.string().required(),
      approvedTimesheets: yup.number().required(),
      unapprovedTimesheets: yup.number().required(),
      entries: yup.array().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = TimesheetSummary.schema.validateSync(obj)

    this.id = validObj.id
    this.approvedTimesheets = validObj.approvedTimesheets
    this.unapprovedTimesheets = validObj.unapprovedTimesheets
    this.entries = validObj.entries.map((entry) => new TimesheetEntry(entry))
  }

  static copy(timesheetSummary: TimesheetSummary): TimesheetSummary {
    return new TimesheetSummary(timesheetSummary)
  }

  static of(
    id: string,
    approvedTimesheets: number,
    unapprovedTimesheets: number,
    entries: TimesheetEntry[]
  ): TimesheetSummary {
    return TimesheetSummary.copy({
      id,
      approvedTimesheets,
      unapprovedTimesheets,
      entries
    })
  }
}
