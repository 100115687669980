import { FactorySerializer, Resource } from '@local/rest-data'
import { SpaDateTime } from '@local/shared-services'
import { withMirageServerDo } from '@local/shared-services'
import { Audit } from '@local/payroll/experience/details/models'

export enum AuditAction {
  Unknown = 0,
  Opened = 1,
  Reset = 2,
  Posted = 3,
  Postponed = 4,
  Checks = 5,
  Other = 100
}

const Serializer = new FactorySerializer((payload) => new Audit(payload))

// This resource hits esx-web
export const AuditResource = new Resource<Audit>(
  Serializer,
  ':client/payroll/details/audit/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server, faker) => {
  const fakeAuditAuthor = () => {
    return Math.random() >= 0.3
      ? `${faker.name.firstName()} ${faker.name.lastName()}`
      : `Admin (${faker.internet.userName()})`
  }

  const getAuditRecords = () => {
    const records = [
      Audit.of(
        AuditAction.Unknown,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        null
      ),
      Audit.of(
        AuditAction.Unknown,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        null
      ),
      Audit.of(
        AuditAction.Unknown,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        null
      )
    ]
      .sort((a, b) => b.date.toMillis() - a.date.toMillis())
      .map((e) => AuditResource.serializer.serialize(e))

    records[0].action = AuditAction.Opened
    records[1].action = AuditAction.Reset
    records[2].action = AuditAction.Opened

    return records
  }

  const getPostedAuditRecords = () => {
    const records = [
      Audit.of(
        AuditAction.Unknown,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        null
      ),
      Audit.of(
        AuditAction.Unknown,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        null
      )
    ]
      .sort((a, b) => b.date.toMillis() - a.date.toMillis())
      .map((e) => AuditResource.serializer.serialize(e))

    records[0].action = AuditAction.Posted
    records[1].action = AuditAction.Opened

    records.push(
      Audit.of(
        AuditAction.Postponed,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        '3/12/2020'
      ),
      Audit.of(
        AuditAction.Checks,
        fakeAuditAuthor(),
        SpaDateTime.fromRecent(),
        null
      )
    )

    return records
  }

  server.db.createCollection('payrollAudit', getAuditRecords())
  server.db.createCollection('payrollPostedAudit', getPostedAuditRecords())
  server.get(
    AuditResource.endpointTemplate(),
    function (schema, request) {
      const payroll = schema.db.payrolls.findBy({
        id: request.params.payroll_id
      })
      return payroll?.status === 6
        ? schema.db.payrollPostedAudit
        : schema.db.payrollAudit
    },
    { timing: 2000 }
  )
})
//#endregion
