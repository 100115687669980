import * as yup from 'yup'
import { SpaDate } from '@local/shared-services'

export class MissedDeadlineOptions {
  readonly calculatedCheckDate: SpaDate
  readonly allowAllEmployeesPayViaCheck: boolean

  private static readonly schema = yup
    .object({
      calculatedCheckDate: yup.mixed().required(),
      allowAllEmployeesPayViaCheck: yup.bool().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = MissedDeadlineOptions.schema.validateSync(obj)

    this.calculatedCheckDate = SpaDate.fromString(validObj.calculatedCheckDate)
    this.allowAllEmployeesPayViaCheck = validObj.allowAllEmployeesPayViaCheck
  }

  static copy(source: MissedDeadlineOptions): MissedDeadlineOptions {
    return new MissedDeadlineOptions(source)
  }

  static of(
    calculatedCheckDate: SpaDate,
    allowAllEmployeesPayViaCheck: boolean
  ): MissedDeadlineOptions {
    return MissedDeadlineOptions.copy({
      calculatedCheckDate,
      allowAllEmployeesPayViaCheck
    })
  }
}

export interface CheckDateOverrideModel {
  date: SpaDate | null
}
