import { FactorySerializer, Resource } from '@local/rest-data'
import { SpaDateTime, withMirageServerDo } from '@local/shared-services'
import { contentType } from '@local/payroll/shared/types/content'
import { PaperCheck } from '@local/payroll/experience/details/models'

const PaperCheckSerializer = new FactorySerializer(
  (payload) => new PaperCheck(payload)
)

export const PaperChecksResource = new Resource<PaperCheck>(
  PaperCheckSerializer,
  ':client/payroll/:payperiod_id/checksPdfs',
  contentType
)

export const PaperChecksResourceEcPayroll = new Resource<PaperCheck>(
  PaperCheckSerializer,
  'payroll/:payperiod_id/checksPdfs',
  contentType
)

//#region Mirage support
withMirageServerDo((server, faker) => {
  const checkWithNoPdfName = [
    PaperCheck.of(
      faker.random.uuid(),
      undefined,
      SpaDateTime.fromString('2000-02-02T00:00:00.000Z')
    )
  ]

  const checks = [
    PaperCheck.of(
      faker.random.uuid(),
      '',
      SpaDateTime.fromString('2000-02-02T00:00:00.000Z')
    ),
    PaperCheck.of(
      faker.random.uuid(),
      faker.internet.url(),
      SpaDateTime.fromString('2000-02-01T00:00:00.000Z')
    )
  ]
  server.db.createCollection('paperChecks', checks)
  let retries = 0
  const maxRetries = faker.random.number(4)
  server.get(
    PaperChecksResource.endpointTemplate(),
    () => {
      retries++
      const checkReturn = faker.random.boolean() ? checks : checkWithNoPdfName
      return retries > maxRetries ? checkReturn : []
    },
    {
      timing: 2000
    }
  )
})
//#endregion
