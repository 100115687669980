import * as yup from 'yup'

export class LabelledValue {
  readonly value: string
  readonly label: string

  static readonly schema = yup
    .object({
      value: yup.string().defined(),
      label: yup.string().defined()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = LabelledValue.schema.validateSync(obj)

    this.value = validObj.value
    this.label = validObj.label
  }

  static copy(source: LabelledValue): LabelledValue {
    return new LabelledValue(source)
  }

  static of(value: string, label: string): LabelledValue {
    return LabelledValue.copy({ value, label })
  }
}
