import * as React from 'react'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { PortalProvider } from '@toasttab/ec-layout'

import { withEcApplication, useCurrentUser, useI18n } from '@local/ec-app'
import { PageLoader } from '@local/payroll/shared/components/pageLoader'

import { ProviderRoutes } from './experience/details/routes'

import { AppConfirmModal } from '@local/payroll/shared/components/modals/v2/AppConfirmModal'
import { AppErrorModal } from '@local/payroll/shared/components/modals/v2/AppErrorModal'

import en from './shared/localization/en-US'
import es from './shared/localization/es'
import { useTranslation } from 'react-i18next'
import { PageTitle } from './shared/components/layout'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

import { ModalContext } from './experience/details/stores/ModalStore'
// import { detailEmployeesPathBase, detailReviewPathBase } from './shared/routes'

import {
  LazyListAchCts,
  LazyGenerateACH,
  LazyPayrollList,
  LazyEmployeeComparison,
  LazyPreflight,
  LazyForm8655List,
  LazyForm8655,
  LazyForm8655Reports
} from './lazy-pages'

import generatedIntrospection from '@local/payroll/gen/queries'
import { useNavigationV3 } from './experience/list/hooks/useNavigationV3'

// Rest link used ONLY for ec-api support: do not use where graphql will work instead!
// react-query also used in places, but we are deprecating that library to consistently use apollo instead
const restLink = new RestLink({ uri: '/' })
const httpLink = new HttpLink({ uri: '/graphql' })

export const PayrollApolloClient = new ApolloClient({
  link: ApolloLink.from([
    // rest link must go first: https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#link-order
    restLink,
    httpLink
  ]),
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Form8655: {
        keyFields: ['feinUuid']
      },
      Navigation: {
        keyFields: ['path'] // this can be removed once this directive is added to the schema
      }
    }
  })
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
})

const PayrollApplication = () => {
  const { client } = useCurrentUser()
  const { addResourceBundle } = useI18n()
  const { t } = useTranslation('payroll')

  const shouldShowNavHeadings = useNavigationV3()

  addResourceBundle('en-US', 'payroll', en)
  addResourceBundle('es', 'payroll', es)

  return (
    <SnackBarProvider>
      <PortalProvider value='data-pui-2'>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ApolloProvider client={PayrollApolloClient}>
              <ModalContext>
                <AppErrorModal />
                <AppConfirmModal />
                <BrowserRouter>
                  <Routes>
                    <Route path='*' element={<ProviderRoutes />} />
                    <Route
                      path='/mvc/:client/admin/payrollops/index'
                      element={<LazyListAchCts />}
                    />
                    <Route
                      path='/mvc/:client/admin/payrollops/achDashboard'
                      element={<LazyGenerateACH />}
                    />
                    <Route
                      path='/:client/payroll/payrolls/list/*'
                      element={<LazyPayrollList />}
                    />

                    <Route
                      path='/:client/payroll/details/employeecomparisonreport/:payroll_id/*'
                      element={<LazyEmployeeComparison />}
                    />
                    <Route
                      path='/:client/payroll/forms/8655'
                      element={<LazyForm8655List />}
                    />
                    <Route
                      path='/:client/payroll/forms/8655/:feinId'
                      element={<LazyForm8655 />}
                    />
                    <Route
                      path='/mvc/:client/Company/Reports/YearEnd'
                      element={<LazyForm8655Reports />}
                    />
                    <Route
                      path='/:client/payroll/details/preflight/:payroll_id/*'
                      element={<LazyPreflight />}
                    />
                    <Route
                      path='/Customer/ManagePayrollToDos/Index.aspx'
                      element={
                        shouldShowNavHeadings ? (
                          <PageTitle
                            title={t('payrolls.title.payrollReminders')}
                            subTitle={t('payrolls.header.title')}
                            backHref={`/${client}/payroll/Payrolls/List`}
                          />
                        ) : null
                      }
                    />
                    <Route
                      path='/mvc/:client/Management/AssetsAndExpenses/Expense'
                      element={
                        shouldShowNavHeadings ? (
                          <PageTitle
                            title={t('payrolls.title.expenses')}
                            subTitle={t('payrolls.header.title')}
                            backHref={`/${client}/payroll/Payrolls/List`}
                          />
                        ) : null
                      }
                    />
                    <Route
                      path='/mvc/:client/Company/Payroll/QuickCalcs'
                      element={
                        shouldShowNavHeadings ? (
                          <PageTitle
                            title={t('payrolls.title.payrollManualChecks')}
                            titleEducationId='manual-checks-title'
                            subTitle={t('payrolls.header.title')}
                            backHref={`/${client}/payroll/Payrolls/List`}
                          />
                        ) : null
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </ModalContext>
            </ApolloProvider>
          </QueryClientProvider>
        </RecoilRoot>
      </PortalProvider>
    </SnackBarProvider>
  )
}

export default withEcApplication(
  PayrollApplication,
  'ec-payroll',
  <PageLoader />
)
