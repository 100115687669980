import * as yup from 'yup'
import { SpaDateTime } from '@local/shared-services'

export class AuditUser {
  readonly uuid: string
  readonly username: string

  private static readonly schema = yup
    .object({
      uuid: yup.string().defined(),
      username: yup.string().defined()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = AuditUser.schema.validateSync(obj)

    this.uuid = validObj.uuid
    this.username = validObj.username
  }

  static of(uuid: string, username: string): AuditUser {
    return AuditUser.copy({ uuid, username })
  }

  static copy(source: AuditUser) {
    return new AuditUser(source)
  }
}

export class AuditEmployee {
  readonly uuid: string | null | undefined
  readonly firstName: string | null | undefined
  readonly lastName: string | null | undefined
  readonly employeeNumber: string | null | undefined

  private static readonly schema = yup
    .object({
      uuid: yup.string().nullable(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      employeeNumber: yup.string().nullable()
    })
    .notRequired()
    .nullable()

  constructor(obj: unknown) {
    const validObj = AuditEmployee.schema.validateSync(obj)

    if (!validObj) return
    this.uuid = validObj.uuid
    this.firstName = validObj.firstName
    this.lastName = validObj.lastName
    this.employeeNumber = validObj.employeeNumber
  }

  static copy(employee: AuditEmployee) {
    return new AuditEmployee(employee)
  }
}

export class PaperCheckAudit {
  readonly id: string
  readonly employee: AuditEmployee | null | undefined
  readonly user: AuditUser
  readonly createdAt: SpaDateTime

  private static readonly schema = yup
    .object({
      id: yup.string().defined(),
      employee: yup.object().notRequired().nullable(),
      user: yup.object().required(),
      createdAt: yup.mixed().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = PaperCheckAudit.schema.validateSync(obj)

    this.id = validObj.id
    this.employee = new AuditEmployee(validObj.employee)
    this.user = new AuditUser(validObj.user)
    this.createdAt = SpaDateTime.fromString(validObj.createdAt)
  }

  static copy(audit: PaperCheckAudit) {
    return new PaperCheckAudit(audit)
  }
}
