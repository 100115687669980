import { Resource } from '@local/rest-data'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { withMirageServerDo } from '@local/shared-services'

// This resources is in esx-web
export const DeleteEmployeeResource = new Resource<PayrollAction>(
  new PayrollActionSerializer(),
  ':client/payroll/:payroll_id/employees/:employee_id/employee/:id'
)

withMirageServerDo((server, _faker) => {
  server.delete(
    DeleteEmployeeResource.endpointTemplate(),
    (schema, request) => {
      //@ts-ignore

      schema.db.payrollEmployee.update({
        employeeId: request.params.employee_id
      })

      return DeleteEmployeeResource.serializer.serialize(
        PayrollAction.of(1, undefined, '')
      )
    },
    { timing: 2000 }
  )
})
