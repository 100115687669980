import { Resource, RestResource } from '@local/rest-data'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { contentType } from '@local/payroll/shared/types/content'
import { withMirageServerDo } from '@local/shared-services'
import { LabelledValue } from '@local/payroll/experience/details/models'
import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'

//this 'void' allows us to just call 'store.createRecord()' and not pass anything
export const AddEmployeeResource = new Resource<void, PayrollAction>(
  new PayrollActionSerializer(),
  ':client/payroll/:payperiod_id/employees/:id',
  contentType
)
export const AddEmployeeResourceEcPayroll = new Resource<void, PayrollAction>(
  new PayrollActionSerializer(),
  'payroll/:payperiod_id/employees/:id',
  contentType
)
export const GetEmployeesInPayGroupResource = new RestResource<LabelledValue>({
  resource: ':client/payroll/config/payperiod/:payperiod_id/employees',
  contentType
})
export const GetEmployeesInPayGroupResourceEcPayroll =
  new RestResource<LabelledValue>({
    resource: 'payroll/config/payperiod/:payperiod_id/employees',
    contentType
  })

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.get(GetEmployeesInPayGroupResource.endpointTemplate(), () => {
    const payrollEmployees: PayrollEmployee[] = server.db
      .payrollEmployee as unknown as PayrollEmployee[]

    const employees: LabelledValue[] = []
    for (let index = 0; index < 10; index++) {
      employees.push(
        LabelledValue.of(
          faker.random.uuid() + '',
          `${faker.name.firstName()} ${faker.name.lastName()}`
        )
      )
    }
    payrollEmployees.forEach((existingEmployee) => {
      employees.push(
        LabelledValue.of(existingEmployee.employeeUuid, existingEmployee.name)
      )
    })
    return employees
  })

  server.post(
    AddEmployeeResource.endpointTemplate(),
    () => PayrollAction.of(1, undefined, ''),
    { timing: 2000 }
  )
})
//#endregion
