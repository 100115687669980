import * as yup from 'yup'

export class SpaErrorModel {
  readonly statusCode: number
  readonly title?: string
  readonly errorCode?: string
  readonly detail?: string

  private static readonly schema = yup
    .object({
      statusCode: yup.number().required().defined(),
      title: yup.string().notRequired(),
      errorCode: yup.string().notRequired(),
      detail: yup.string().notRequired()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = SpaErrorModel.schema.validateSync(obj)

    this.statusCode = validObj.statusCode
    this.title = validObj.title
    this.errorCode = validObj.errorCode
    this.detail = validObj.detail
  }

  static copy(source: SpaErrorModel): SpaErrorModel {
    return new SpaErrorModel(source)
  }

  static of(
    statusCode: number,
    title?: string,
    errorCode?: string,
    detail?: string
  ): SpaErrorModel {
    return SpaErrorModel.copy({
      statusCode,
      title,
      errorCode,
      detail
    })
  }
}
