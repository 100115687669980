export enum EnumBulkAddDeductionType {
  AllEmployees = 1,
  AllSalaried = 2,
  AllHourly = 3,
  SelectedEmployees = 4
}

export class BulkEmployeeDeduction {
  constructor(
    readonly employeeIds: Array<string>,
    readonly deductionCodeId: string,
    readonly deductionCodeName: string,
    readonly type: number,
    readonly amount: number,
    readonly isPercentage: boolean
  ) {}
}
