import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { ISerializer } from '@local/rest-data'
import * as yup from 'yup'

export class PayrollActionSerializer<T>
  implements ISerializer<any, PayrollAction<T>>
{
  constructor(readonly create?: (obj: unknown) => T) {}

  serialize(model: any): any {
    return { ...model } as unknown
  }

  deserialize(model: any): PayrollAction<T> {
    return new PayrollAction<T>(
      model,
      this.create ? (obj) => this.create!(obj) : undefined
    )
  }
}

export class PayrollActionArraySerializer<T>
  implements ISerializer<any, PayrollAction<T[]>>
{
  constructor(readonly create: (obj: unknown) => T) {}

  serialize(model: any): any {
    return { ...model } as unknown
  }

  deserialize(model: any): PayrollAction<T[]> {
    return new PayrollAction<T[]>(model, (obj: unknown): T[] => {
      const validObj = yup.array().defined().validateSync(obj)

      return validObj.map((obj) => this.create(obj))
    })
  }
}
