import { StringLabelValuePair } from '@local/payroll/shared/types/buffetOptions'

const StringIsNumber = (value: any) => isNaN(Number(value)) === false

// Returns an array of the valid vaues for an enum.
//
// Usage: unfortunately, Typescript enums do not have any base class, so there is no
// way to make a fully generic function. This interface has a generic parameter T that
// represents the return type (an enum), and you will also pass the same thing as the
// function argument. This permits the right type and value validation in a Yup call:
//
//    yup.number().required().oneOf(EnumValueArray<AuditAction>(AuditAction))
//
export function EnumToValueArray<T>(enumClass: any): T[] {
  // The internal representation of an enum has both keys and values of the enum in a single array.
  // The slice from middle to end gives the values, and start to middle gives the keys (strings)
  //
  // https://www.typescriptlang.org/play?#code/KYOwrgtgBACghgTwE4HsA2aDKAXO2wDOUA3gFBRRggDWIKA7iFALxQAMANFAPTdQD8-clADGSYHmAATFlACMXXlDrYo4uFIRRsKNcABuAS2D1hKAA6hpsgEyK+KvRq069Rk8IIiAFtLBprVgBme1E4NBF-PEMUJkMmc1QAc3ECAmERcMi0SRlWABZQzIio7BiQIhEUCHMA7GAudU1tXXMUAmxhfWAkQwAzYzyoAFZQwRYAPmUUVUJpYTaOwKgANlDF1SqauvmKJKskXIB9OB9ZAHZQgEEAYQAJKAAxQwCifZAe3Kg+lCQoYGALSgACNAeY4IYpAtUCJgGl4klZABOJHrRCoDBQAg9IywqDmfxobS+PRVJAyOAgGSGVQdOBIVSJFCw+EgRE0rjxepICDSQySLG4fBEUHYegAphBKCUmT5Tw+PxoBHIgAcY340rSkGVBGqwGw3mVovFoEFeEIUBs0qpUCCwj5ZTZshVLtCVCkrXR6DQXBESpE1GsDpBYFUjliUAsVhkdOFUAQ+oAdAt2o7EawUaieHxsIcKjTyma46CfuJvvFwvjU7soIYar9VKw5GwW9ntHmCAWI7GLfRDQFa-WGcqyryCL59ekAL6kUhVCroYCJtAoJIACgA8sCAFbAETYRP6cJgOFr+DIb04c0EACUN6AA
  const enumKeysAndValues = Object.values(enumClass)
  return enumKeysAndValues.slice(enumKeysAndValues.length / 2) as T[]
}

// TODO: The function below is used in the ACH app, but is technically incorrect, because an enum can
// have any value at all, including a string. I.e., this enum is valid: enum FooBar { foo = 'bar' }
//
// This function will convert your name into an Array with value prop being the integer value of your enums and label prop being the string name of the enum values
// Eg. enum Example { One = 1, Two, Three } will be converted to [{ label: 'One', value: '1'}, { label: 'Two', value: '2'}, { label: 'Three', value: '3'}
export function EnumToLabelValuePairs(
  enumType: any
): Array<StringLabelValuePair> {
  const filteredKeys = Object.keys(enumType).filter(StringIsNumber)

  const selectorOptions: Array<StringLabelValuePair> = []
  filteredKeys.forEach((key) =>
    selectorOptions.push({ label: enumType[key], value: key })
  )

  return selectorOptions
}

// replacement for the above -- created after Buffet Select props shape was changed, also solves the TS defficiencies mentioned above
export const enumToOptions = (enumType: any): Array<StringLabelValuePair> =>
  Object.values(enumType)
    // calling Object.values(ENUM) results in an array that looks like [...enum labels, ...enum values]
    .filter((_val, index) => index < Object.values(enumType).length / 2)
    .map((val) => ({ label: val, value: val } as StringLabelValuePair))

// This function allows you to pass a string value, the enum type and convert that string to enum.
// The caller should check for undefined if we're not able to convert the string to enum
export function StringToEnum<T>(stringValue: string, enumType: any): T {
  const stringKey: keyof typeof enumType = stringValue as keyof typeof enumType
  const enumValue: T = enumType[stringKey]
  return enumValue
}

// This function allows you to pass a number value, the enum type and convert that number to enum.
// The caller should check for undefined if we're not able to convert the number to enum
export function NumberToEnum<T>(numberValue: string, enumType: any): T {
  const numberKey: keyof typeof enumType = numberValue as keyof typeof enumType
  let stringKey: keyof typeof enumType = ''
  // Make sure there exists a value for this number
  if (enumType[numberKey] !== undefined) {
    stringKey = enumType[numberKey]
  }
  // If the value exists, send the enum the numerical value of the enum back. Sending the string mapping back doesn't work as well.
  const enumValue: T = enumType[stringKey]
  return enumValue
}
