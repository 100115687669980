import { withMirageServerDo } from '@local/shared-services'
import { RestResource } from '@local/rest-data'
import { DeductionCode } from '../../models/codes/deductionCode'
import { DeductionCodeFactory } from '@local/payroll/shared/models/factories'
import { contentType } from '../../../../shared/types/content'

export const DeductionCodeResource = new RestResource<DeductionCode>({
  resource: ':client/payroll/config/deductioncode'
})

export const DeductionCodeResourceEcPayroll = new RestResource<DeductionCode>({
  resource: 'payroll/config/deductioncodes?isToastPayoutDeductionCode=false',
  contentType: contentType
})

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.get(DeductionCodeResource.endpointTemplate(), function (_schema) {
    return [
      'Federal',
      'State',
      'Local',
      'Garnishment',
      'Medical',
      'Dental',
      'Life'
    ].map((name) =>
      DeductionCodeFactory.copy({
        value: faker.random.number() + '',
        label: name,
        isActive: true,
        isSameDayPayRedemption: false,
        isTipsPayoutRedemption: false
      })
    )
  })
})
//#endregion
