import { useNavigate } from 'react-router-dom'

export const RoutingHelpers = {
  isASPX: (url = '') => url?.indexOf('.aspx') > -1,
  isLegacyMVC: (url = '') =>
    url?.startsWith('/mvc/') ||
    (url?.startsWith('http') && url?.indexOf('/mvc/') > -1)
}

export const useServerRedirect = () => {
  return (url = '') => {
    window.location.href = url
  }
}

export const useClientRedirect = () => {
  const navigate = useNavigate()
  const serverRedirect = useServerRedirect()

  return (url: string | undefined | null = '') => {
    if (!url) {
      console.info('attempted to redirect to blank page', url)
    } else {
      if (RoutingHelpers.isASPX(url) || RoutingHelpers.isLegacyMVC(url)) {
        serverRedirect(url)
      } else {
        navigate(url)
      }
    }
  }
}
