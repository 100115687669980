import {
  FactorySerializer,
  BaseSerializer,
  Resource,
  RestResource
} from '@local/rest-data'
import { SpaDate, withMirageServerDo } from '@local/shared-services'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { contentType } from '@local/payroll/shared/types/content'
import {
  CheckDateOverrideModel,
  MissedDeadlineOptions
} from '@local/payroll/experience/details/models'

const Serializer = new FactorySerializer(
  (payload) => new MissedDeadlineOptions(payload)
)

// This resource hits esx-web
export const MissedDeadlineOptionsResource =
  new Resource<MissedDeadlineOptions>(
    Serializer,
    ':client/payroll/details/missedDeadlineOptions/:payroll_id'
  )

// This resource hits esx-web
export const EnforceDeadlineResource = new RestResource<PayrollAction>({
  resource: ':client/payroll/details/enforceDeadline/:payroll_id'
})

class CheckDateOverrideSerializer extends BaseSerializer<CheckDateOverrideModel> {
  serialize(modelDate: CheckDateOverrideModel) {
    return {
      date: modelDate?.date?.toFormat('yyyy-MM-dd')
    }
  }
}

export const UpdateCheckDateResource = new Resource<
  CheckDateOverrideModel,
  PayrollAction
>(
  new CheckDateOverrideSerializer(),
  ':client/payroll/:payperiod_id/checkdateoverride',
  contentType
)

export const UpdateCheckDateResourceEcPayroll = new Resource<
  CheckDateOverrideModel,
  PayrollAction
>(
  new CheckDateOverrideSerializer(),
  'payroll/:payperiod_id/checkdateoverride',
  contentType
)
//#region Mirage support
withMirageServerDo((server) => {
  server.get(EnforceDeadlineResource.endpointTemplate(), function () {
    return {
      result: true
    }
  })
  server.get(MissedDeadlineOptionsResource.endpointTemplate(), function () {
    return {
      calculatedCheckDate: SpaDate.fromToday().plus(3),
      allowAllEmployeesPayViaCheck: true
    }
  })
  server.post(UpdateCheckDateResource.endpointTemplate(), function () {
    return {}
  })
})
//#endregion
