import { RestResource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { contentType } from '@local/payroll/shared/types/content'
import { AchSummary } from '@local/payroll/experience/details/models'

export const AchSummaryResource = new RestResource<AchSummary | null>({
  resource: ':client/payroll/:payperiod_id/achSummary',
  contentType: contentType
})

export const AchSummaryResourceEcPayroll = new RestResource<AchSummary | null>({
  resource: 'payroll/:payperiod_id/achSummary',
  contentType: contentType
})

//#region Mirage support
withMirageServerDo((server, faker) => {
  const achSummary = faker.random.boolean()
    ? [
        AchSummary.of(
          Number(faker.finance.amount()),
          Number(faker.finance.amount()),
          Number(faker.finance.amount()),
          Number(faker.finance.amount()),
          Number(faker.finance.amount())
        )
      ]
    : []

  server.get(
    AchSummaryResource.endpointTemplate(),
    function () {
      server.db.createCollection('achSummary', achSummary)
      return server.db.achSummary
    },
    {
      timing: 4000
    }
  )
})
//#endregion
