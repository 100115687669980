import { RestSerializer, ISerializer } from '../serializers'
import { FetchStore, IStore } from '../stores/fetch'

type ContentTypes =
  | 'application/json;charset=utf-8'
  | 'application/vnd.com.toasttab.ec.payroll.v1+json'
  | 'application/vnd.com.toasttab.ec.timesheet.v1+json'
  | 'application/vnd.com.toasttab.ec.tax.reports.v1+json'

/**
 * A resource is a object that provides a way to access data from remote sources
 *
 * @example
 *
 * export const EmployeeResource = new Resource<Employee>(
 * new Serializer(),
 * ':client/payroll/employees/list/:payroll_id'
 * )
 *
 */
export class Resource<SourceType, ResultType = SourceType, ParamsType = any> {
  private store: IStore<SourceType, ResultType>

  /**
   * A endpoint that can be used to build an endpoint or to help define mock endpoints
   */
  public endpointTemplate() {
    return `${this.host}${this.resource}`
  }
  constructor(
    readonly serializer: ISerializer<SourceType, ResultType>,
    readonly resource: string,
    readonly contentType: ContentTypes = 'application/json;charset=utf-8',
    readonly host: string = '/'
  ) {
    this.store = new FetchStore<SourceType, ResultType>(
      this.endpointTemplate(),
      serializer,
      contentType
    )
  }
  /**
   * Provides a store with the supplied params applied to the endpoint template
   * @param params an object that is applied to the endpoint template
   *
   * @returns a store
   */
  public getStore(params: ParamsType) {
    this.store.applyRouteParams(this.endpointTemplate(), params)
    return this.store
  }
}

interface ResourceConfig {
  resource: string
  contentType?: ContentTypes
  host?: string
}

/**
 * A RestResource is extended from Resource and provides a simple default for the serializer
 * @example
 *
 * export const EmployeeResource = new RestResource<Employee>(
 * ':client/payroll/employees/list/:payroll_id'
 * )
 */
export class RestResource<
  SourceType,
  ResponseType = SourceType,
  ParamsType = any
> extends Resource<SourceType, ResponseType, ParamsType> {
  constructor(readonly config: ResourceConfig) {
    super(
      new RestSerializer<SourceType, ResponseType>(),
      config.resource,
      config.contentType,
      config.host
    )
  }
}
