import { RestResource } from '@local/rest-data'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import { withMirageServerDo } from '@local/shared-services'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { SpaErrorModel } from '@local/payroll/shared/models/spaErrorModel'

export const ImportTimesheetsResource = new RestResource<PayrollAction>({
  // This resource hits esx-web
  resource: ':client/payroll/details/import/:payroll_id'
})

//#region Mirage support
withMirageServerDo((server) => {
  server.post(
    ImportTimesheetsResource.endpointTemplate(),
    function (schema, request) {
      const payroll = schema.db.payrolls.findBy({
        id: request.params.payroll_id
      })
      schema.db.payrolls.update({
        ...payroll,
        status: PayrollStatus.import,
        posting: false
      })

      // we need to serialize responses as we expect them to come from the server.
      return ImportTimesheetsResource.serializer.serialize(
        Math.random() > 0.1
          ? PayrollAction.of(
              1,
              undefined,
              `/${request.params.client}/payroll/employees/spreadsheet/${request.params.payroll_id}`
            )
          : PayrollAction.of(
              undefined,
              [SpaErrorModel.of(500, 'Server Error', 'LockConflict')],
              ''
            )
      )
    },
    { timing: 2000 }
  )
})
//#endregion
