import { SpaDate } from '@local/shared-services'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import {
  detailPreflightPath,
  detailReviewPath
} from '@local/payroll/shared/routes'
import { PayrollStatusV2 } from '../../../gen/queries'

const canReset = (status: PayrollStatus) =>
  [PayrollStatus.opened].includes(status)

const isPosted = (status: PayrollStatus) =>
  [PayrollStatus.posted, PayrollStatus.generated_ach].includes(status)

const isCalculating = (status: PayrollStatus) =>
  [
    PayrollStatus.scheduled,
    PayrollStatus.scheduling,
    PayrollStatus.processing
  ].includes(status)

const isStarted = (status: PayrollStatus) =>
  ![PayrollStatus.created, PayrollStatus.unknown].includes(status)

const isCalculated = (status: PayrollStatus) =>
  PayrollStatus.calculated === status

const isScheduled = (status: PayrollStatus) =>
  PayrollStatus.scheduled === status

const isRunningEarly = (status: PayrollStatus, checkDate: SpaDate) =>
  !PayrollStatusHelpers.isStarted(status) &&
  checkDate.isGreater(SpaDate.fromToday().plus(7))

// TODO: Eventually this helper will be applied to the entire Payroll workflow wizard,
// so that you will automatically be filtered to the correct page when visiting the workflow fresh,
// thus avoiding having to fix _every_ entry point into the wizard.
const statusToStepPage = (
  status: PayrollStatus,
  { client, uuid, secureId }: { client: string; uuid: string; secureId: string }
): string => {
  switch (status) {
    // The review page itself will handle whether to show a loader or the full review
    case PayrollStatus.scheduled:
    case PayrollStatus.scheduling:
    case PayrollStatus.processing:
    case PayrollStatus.calculated:
      return detailReviewPath({
        client,
        uuid
      })
    default:
      return detailPreflightPath({ client, uuid, secureId })
  }
}

// TODO: statusV2 is the desired longterm enum for use
// however, we are currently using status and need to gradually migrate existing uses
const statusV2ToStatus = (status: PayrollStatusV2): PayrollStatus => {
  switch (status) {
    case PayrollStatusV2.CALCULATED:
      return PayrollStatus.calculated
    case PayrollStatusV2.CREATED:
      return PayrollStatus.created
    case PayrollStatusV2.GENERATED_ACH:
      return PayrollStatus.generated_ach
    case PayrollStatusV2.IMPORT:
      return PayrollStatus.import
    case PayrollStatusV2.OPENED:
      return PayrollStatus.opened
    case PayrollStatusV2.POSTED:
      return PayrollStatus.posted
    case PayrollStatusV2.PROCESSING:
      return PayrollStatus.processing
    case PayrollStatusV2.SCHEDULING:
      return PayrollStatus.scheduling
    case PayrollStatusV2.VERIFIED:
      return PayrollStatus.verified
    case PayrollStatusV2.UNKNOWN:
      return PayrollStatus.unknown
    // these are statuses that are extremely seldomly used, and not relevant for UX
    // we therefore just map to unknown for completeness sake
    case PayrollStatusV2.EDITING:
    case PayrollStatusV2.EMPLOYEE_MAINTENANCE:
    case PayrollStatusV2.G2N:
    case PayrollStatusV2.REPORTS:
    case PayrollStatusV2.POST_PAYROLL:
    case PayrollStatusV2.PR_MAINTENANCE:
    case PayrollStatusV2.POSTING:
    case PayrollStatusV2.SPREADSHEET:
    case PayrollStatusV2.STATUS_CHANGES:
    case PayrollStatusV2.VACATION:
    default:
      return PayrollStatus.unknown
  }
}

export const PayrollStatusHelpers = {
  canReset,
  isRunningEarly,
  isPosted,
  isCalculating,
  isCalculated,
  isStarted,
  isScheduled,
  statusToStepPage,
  statusV2ToStatus
}
