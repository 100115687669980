import { withMirageServerDo } from '@local/shared-services'
import { RestResource } from '@local/rest-data'
import { PayrollRouteParams } from '@local/payroll/shared/types/params'
import { EmployeeComparison } from '@local/payroll/experience/details/models'

// This resource hits esx-web
export const EmployeeComparisonResource = new RestResource<
  EmployeeComparison,
  EmployeeComparison,
  PayrollRouteParams
>({ resource: ':client/payroll/details/employeecomparison/:payroll_id' })

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.get(EmployeeComparisonResource.endpointTemplate(), function (_schema) {
    const result: Array<EmployeeComparison> = []

    for (let index = 0; index < 500; index++) {
      const inCurrent = Math.random() >= 0.1
      const inPrevious = inCurrent ? Math.random() >= 0.1 : true // this won't let both be false. At least the employee needs to be either included in the previous or current

      result.push({
        id: faker.random.number() + '',
        name: `${faker.name.firstName()} ${faker.name.lastName()} (${faker.random.number()})`,
        previousHours: inPrevious
          ? faker.random.number({ min: 1, max: 500, precision: 0.02 })
          : 0,
        currentHours: inCurrent
          ? faker.random.number({ min: 1, max: 500, precision: 0.02 })
          : 0,
        previousGross: inPrevious ? faker.random.number(20000) : 0,
        currentGross: inCurrent ? faker.random.number(20000) : 0,
        inCurrent: inCurrent,
        inPrevious: inPrevious
      })
      index++
    }

    return result
  })
})
// #endregion
