import { RestResource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { ImportAudit } from '@local/payroll/experience/details/models'

// This resource hits esx-web
export const ImportAuditResource = new RestResource<ImportAudit>({
  resource: ':client/payroll/details/importaudit/:payroll_id'
})

//#region Mirage support
withMirageServerDo((server, faker) => {
  const fakeAuditAuthor = () => {
    return Math.random() >= 0.3
      ? `${faker.name.firstName()} ${faker.name.lastName()}`
      : `Admin (${faker.internet.userName()})`
  }

  function getAuditRecords() {
    return [
      new ImportAudit('2', faker.system.fileName(), fakeAuditAuthor()),
      new ImportAudit('1', faker.system.fileName(), fakeAuditAuthor())
    ].map((e) => ImportAuditResource.serializer.serialize(e))
  }

  server.db.createCollection('payrollImportAudit', getAuditRecords())
  server.get(
    ImportAuditResource.endpointTemplate(),
    function (schema, _request) {
      return schema.db.payrollImportAudit
    },
    { timing: 2000 }
  )
})
//#endregion
