import * as yup from 'yup'
import { CheckCode } from '@local/payroll/gen/queries'

export const CheckCodeFactory = {
  create(obj: unknown) {
    return yup
      .object({
        value: yup.string().defined(),
        label: yup.string().defined()
      })
      .defined()
      .validateSync(obj)
  },
  copy(source: CheckCode) {
    return this.create(source)
  }
}
