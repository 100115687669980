import * as yup from 'yup'

export class EmployeeSummary {
  readonly previousEmployeeCount: number
  readonly currentEmployeeCount: number
  readonly employeesPaidViaPaycheck: number
  readonly employeesPaidViaDirectDeposit: number
  readonly previousHoursPaid: number
  readonly currentHoursPaid: number
  readonly otHoursPaid: number
  readonly salariedEmployees: number

  private static readonly schema = yup
    .object({
      previousEmployeeCount: yup.number().required(),
      currentEmployeeCount: yup.number().required(),
      employeesPaidViaPaycheck: yup.number().required(),
      employeesPaidViaDirectDeposit: yup.number().required(),
      previousHoursPaid: yup.number().required(),
      currentHoursPaid: yup.number().required(),
      otHoursPaid: yup.number().required(),
      salariedEmployees: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = EmployeeSummary.schema.validateSync(obj)

    this.previousEmployeeCount = validObj.previousEmployeeCount
    this.currentEmployeeCount = validObj.currentEmployeeCount
    this.employeesPaidViaPaycheck = validObj.employeesPaidViaPaycheck
    this.employeesPaidViaDirectDeposit = validObj.employeesPaidViaDirectDeposit
    this.previousHoursPaid = validObj.previousHoursPaid
    this.currentHoursPaid = validObj.currentHoursPaid
    this.otHoursPaid = validObj.otHoursPaid
    this.salariedEmployees = validObj.salariedEmployees
  }

  static copy(source: EmployeeSummary): EmployeeSummary {
    return new EmployeeSummary(source)
  }

  static of(
    previousEmployeeCount: number,
    currentEmployeeCount: number,
    employeesPaidViaPaycheck: number,
    employeesPaidViaDirectDeposit: number,
    previousHoursPaid: number,
    currentHoursPaid: number,
    otHoursPaid: number,
    salariedEmployees: number
  ): EmployeeSummary {
    return EmployeeSummary.copy({
      previousEmployeeCount,
      currentEmployeeCount,
      employeesPaidViaPaycheck,
      employeesPaidViaDirectDeposit,
      previousHoursPaid,
      currentHoursPaid,
      otHoursPaid,
      salariedEmployees
    })
  }
}
