import { EarningCode } from '@local/payroll/gen/queries'
import * as yup from 'yup'

export const EarningCodeFactory = {
  schema: yup
    .object({
      value: yup.string().defined(),
      label: yup.string().defined(),
      isHourly: yup.boolean().required(),
      isOT: yup.boolean().required()
    })
    .defined(),
  create(obj: unknown): EarningCode {
    return this.schema.validateSync(obj)
  },
  copy(earningCode: EarningCode) {
    return this.create(earningCode)
  }
}
