import * as yup from 'yup'

import { PayCheck } from '@local/payroll/shared/types/graphDeprecated'

import { EmployeeDeductionFactory } from './EmployeeDeductionFactory'
import { EmployeeEarningFactory } from './EmployeeEarningFactory'
import { CheckCodeFactory } from './CheckCodeFactory'

export const PayCheckFactory = {
  create(obj: unknown): PayCheck {
    const paystub = yup
      .object({
        id: yup.string().required(),
        paidByCheck: yup.boolean().required(),
        number: yup.number().required(),
        earnings: yup.array().defined(),
        deductions: yup.array().defined(),
        isVoid: yup.boolean().required(),
        isReadOnly: yup.boolean().required(),
        isManual: yup.boolean().defined(),
        checkCode: yup.object().defined()
      })
      .defined()
      .validateSync(obj)

    const earnings = paystub.earnings.map((e) =>
      EmployeeEarningFactory.create(e)
    )
    earnings.sort((a, b) =>
      // a goes first if it is in the first week and b is not. Otherwise, if a and b are in the same week then sort by name.
      (a.isFirstWeek && !b.isFirstWeek) ||
      (a.isFirstWeek === b.isFirstWeek && a.name < b.name)
        ? -1
        : 1
    )

    const deductions = paystub.deductions.map((d) =>
      EmployeeDeductionFactory.create({ isReadOnly: false, ...d })
    )
    deductions.sort((a, b) => (a.name < b.name ? -1 : 1))

    return {
      ...paystub,
      earnings: earnings,
      deductions: deductions,
      checkCode: CheckCodeFactory.create(paystub.checkCode)
    }
  },
  copy(source: PayCheck) {
    return this.create(source)
  }
}
