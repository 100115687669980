export enum EnumMessageCategory {
  ToDo = 1,
  Check = 2,
  Void = 3,
  Undefined = 0
}

export enum EnumMessageAmountType {
  Undefined = 0,
  Currency = 1,
  Value = 2,
  Percent = 3
}

export class PayrollMessage {
  static default = new PayrollMessage(
    0,
    EnumMessageAmountType.Undefined,
    EnumMessageCategory.Undefined,
    '',
    '',
    ''
  )

  constructor(
    readonly amount: number,
    readonly amountType: EnumMessageAmountType,
    readonly messageCategory: EnumMessageCategory,
    readonly codeName: string,
    readonly author: string,
    readonly whom: string
  ) {}
}
