import { RestResource } from '@local/rest-data'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import { withMirageServerDo } from '@local/shared-services'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'

// Resource hits esx-web
export const PostedPayrollResource = new RestResource<
  void,
  PayrollAction<number>
>({
  resource: ':client/payroll/details/post/:payroll_id'
})

//#region Mirage support
withMirageServerDo((server) => {
  server.post(
    PostedPayrollResource.endpointTemplate(),
    function (schema, request) {
      const payroll = schema.db.payrolls.findBy({
        id: request.params.payroll_id
      })
      schema.db.payrolls.update({
        ...payroll,
        status: PayrollStatus.posted,
        posting: false
      })

      return PayrollAction.of(1, undefined, '/')
    },
    { timing: 2000 }
  )
})
//#endregion
