import * as yup from 'yup'
import { Level } from '../models/level'

export type LevelResult = {
  level: Level
  value?: string
}

export type LevelOption = {
  uuid: string
  name: string
  sequenceId: number
  isOrphan: boolean
}

export class LevelSelectionV1 {
  readonly sequenceId: number
  readonly id: string

  private static readonly schema = yup
    .object({
      id: yup.string().required(),
      sequenceId: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = LevelSelectionV1.schema.validateSync(obj)

    this.sequenceId = validObj.sequenceId
    this.id = validObj.id
  }
  static copy(levelSelection: LevelSelectionV1) {
    return new LevelSelectionV1(levelSelection)
  }
}

export class LevelSelection {
  readonly sequenceId: number
  readonly uuid: string

  private static readonly schema = yup
    .object({
      uuid: yup.string().required(),
      sequenceId: yup.number().required()
    })
    .defined()

  constructor(obj: unknown) {
    const validObj = LevelSelection.schema.validateSync(obj)

    this.sequenceId = validObj.sequenceId
    this.uuid = validObj.uuid
  }
  static copy(levelSelection: LevelSelection) {
    return new LevelSelection(levelSelection)
  }
}
