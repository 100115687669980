//@ts-nocheck
/* eslint-disable */
export function initializePendo(apiKey, user, window: Window) {
  ;(function (p, e, n, d, o) {
    var v, w, x, y, z
    o = p[d] = p[d] || {}
    o._q = []
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad']
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            )
          }
      })(v[w])
    y = e.createElement(n)
    y.async = !0
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
    z = e.getElementsByTagName(n)[0]
    z.parentNode.insertBefore(y, z)
  })(window, window.document, 'script', 'pendo')
  pendo.initialize({
    visitor: {
      id: user.userUuid,

      //custom
      roles: user.staticRoleNames,
      userType: user.userType,
      homeState: user.homeState,
      customerUuid: user.customerUuid,
      employeeUuid: user.employeeUuid,
      isPosUser: user.isPosUser,
      email: user.email || null,
      username: user.name,

      //Remove this code when completing https://toasttab.atlassian.net/browse/ECHR-555
      pendoOnboardingStatus: user.pendoOnboardingStatus || 'onboarding-complete'
    },

    account: {
      id: user.client
    }
  })
}
