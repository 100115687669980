import { Resource } from '@local/rest-data'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { contentType } from '@local/payroll/shared/types/content'
import { withMirageServerDo } from '@local/shared-services'
import {
  CheckCodeFactory,
  PayCheckFactory
} from '@local/payroll/shared/models/factories'

import { AddPayCheck } from '@local/payroll/experience/details/models'
import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'

export const AddPayCheckResource = new Resource<AddPayCheck, PayrollAction>(
  new PayrollActionSerializer(),
  ':client/payroll/:payperiod_id/employees/:employee_id/paycheck',
  contentType
)

export const AddPayCheckResourceEcPayroll = new Resource<
  AddPayCheck,
  PayrollAction
>(
  new PayrollActionSerializer(),
  'payroll/:payperiod_id/employees/:employee_id/paycheck',
  contentType
)

//#region Mirage support
withMirageServerDo((server, faker) => {
  server.post(
    AddPayCheckResource.endpointTemplate(),
    (schema, request) => {
      const { employee_id } = request.params

      const employee: PayrollEmployee = server.db.payrollEmployee.findBy({
        employeeUuid: employee_id
      })

      const newPayCheck = PayCheckFactory.copy({
        id: faker.random.number() + '',
        paidByCheck: false,
        number:
          Math.max(
            ...employee.paystubs.map(function (o) {
              return o.number
            })
          ) + 1,
        earnings: [],
        deductions: [],
        isVoid: false,
        isReadOnly: false,
        isManual: false,
        checkCode: CheckCodeFactory.copy({
          value: faker.random.number() + '',
          label: 'Bonus'
        })
      })

      employee.paystubs.push(newPayCheck)
      return {}
    },
    { timing: 2000 }
  )
})
//#endregion
