import { PayrollEmployee } from '@local/payroll/shared/types/graphDeprecated'
import * as yup from 'yup'

import { PayCheckFactory } from './PayStubFactory'

export const PayrollEmployeeFactory = {
  create(obj: unknown): PayrollEmployee {
    const employee = yup
      .object({
        id: yup.string().required(),
        name: yup.string().defined(), // TODO: make this required, and change all te mock data to non-empty name
        employeeNumber: yup.string().defined(),
        employeeId: yup.string().required(),
        employeeUuid: yup.string().required(),
        payperiodUuid: yup.string().defined(),
        paystubs: yup.array().defined()
      })
      .defined()
      .validateSync(obj)

    const paystubs = employee.paystubs.map((ps) => PayCheckFactory.create(ps))
    paystubs.sort((a, b) => a.number - b.number)

    return {
      ...employee,
      displayName: `${employee.name} (${employee.employeeNumber})`,
      paystubs,
      payChecks: paystubs
    }
  },
  copy(source: Omit<PayrollEmployee, 'displayName'>) {
    return this.create(source)
  }
}
