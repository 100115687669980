import { FactorySerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import {
  EnumEntryType,
  TimesheetEntry,
  TimesheetSummary
} from '@local/payroll/experience/details/models'

const TimesheetSummarySerializer = new FactorySerializer(
  (payload) => new TimesheetSummary(payload)
)

// resource hits esx-web
export const TimesheetSummaryResource = new Resource<TimesheetSummary>(
  TimesheetSummarySerializer,
  ':client/payroll/details/timesheetSummary/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server) => {
  server.db.createCollection('payrollTimesheetSummary')
  server.get(
    TimesheetSummaryResource.endpointTemplate(),
    function (schema) {
      return schema.db.payrollTimesheetSummary.firstOrCreate(
        TimesheetSummaryResource.serializer.serialize(
          TimesheetSummary.of('1', 64, 2, [
            TimesheetEntry.of('Regular hours', EnumEntryType.Hourly, 400.25, 0),
            TimesheetEntry.of('Overtime hours', EnumEntryType.Hourly, 20, 0),
            TimesheetEntry.of('Vacation hours', EnumEntryType.Hourly, 0, 0),
            TimesheetEntry.of('Sick hours', EnumEntryType.Hourly, 8, 0),
            TimesheetEntry.of('Cash tips', EnumEntryType.Salary, 0, 200),
            TimesheetEntry.of('Non-cash tips', EnumEntryType.Salary, 0, 10000)
          ])
        )
      )
    },
    { timing: 4000 }
  )
})
//#endregion
