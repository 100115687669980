import * as React from 'react'
import { useApolloClient } from '@apollo/client'

import {
  PayrollResource,
  PayrollStatus
} from '@local/payroll/shared/models/payrollDetail'

import create from 'zustand'
import createContext from 'zustand/context'

import { PayrollRouteParams } from '@local/payroll/shared/types/params'
import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'

import {
  GetPayPeriodByUuidQuery,
  GetPayPeriodByUuidQueryVariables,
  PayPeriod
} from '@local/payroll/gen/queries'
import { isLegacyPayrollId } from '../hooks/useMigrationParams'
import { SpaDate } from '@local/shared-services'

const { Provider, useStore } = createContext<PayPeriodStoreState>()

interface PayPeriodStoreState {
  // TODO: This type incorrectly assumes that we're always loading the full PayPeriod,
  // which is not necessarily true e.g. if the GQL query is not updated (accidentally or otherwise).
  // We can remove all this over time as we pivot to using the new ec-payroll graph.
  payPeriod: PayPeriod
  setStatus: (status: PayPeriod['status']) => void
  setStatusToPosted: () => void
  schedule: () => void
  refetch: () => Promise<PayPeriod | undefined>
  isLoading: boolean
  updateFinalCheckDate: (finalCheckDate: PayPeriod['finalCheckDate']) => void
}

const createStore = ({
  payPeriod,
  refetch,
  isLoading
}: {
  payPeriod: PayPeriod
  isLoading: boolean
  refetch: ReturnType<typeof useQuery<PayPeriod>>['refetch']
}) =>
  create<PayPeriodStoreState>((set) => ({
    isLoading,
    payPeriod,
    // TODO: This is an extremely side-effecty and BUGGY pattern, since it is far too optimistic about client-side state updates.
    // As we migrate all of these endpoints to graph, we can begin to refactor to make a clearer single source of truth.
    setStatus: (status: PayrollStatus) =>
      set((state) => ({ payPeriod: { ...state.payPeriod, status } })),
    setStatusToPosted: () =>
      set((state) => ({
        payPeriod: {
          ...state.payPeriod,
          status: PayrollStatus.posted
        }
      })),
    updateFinalCheckDate: (finalCheckDate: SpaDate) =>
      set((state) => ({ payPeriod: { ...state.payPeriod, finalCheckDate } })),
    schedule: () =>
      set((state) => ({
        payPeriod: {
          ...state.payPeriod,
          status: PayrollStatus.scheduled
        }
      })),
    refetch: async () => {
      const payPeriod = (await refetch()).data

      if (payPeriod) {
        set(() => ({
          payPeriod
        }))
      }

      return payPeriod
    }
  }))

export const PayPeriodQuery = gql`
  query GetPayPeriodByUuid($payPeriodUuid: ID!) {
    payperiod(payPeriodUuid: $payPeriodUuid) {
      uuid
      name
      status
      startDate
      endDate
      checkDate
      checkDateOverride
      checkCode {
        value
        label
      }
      forceLiveChecks
      taxDebitDate
      dueDate
      postDeadline
      datePosted
      isReadOnly
      debitDate
      finalCheckDate
      deliveryMethod
      adjustmentUrl
      timesheetsUrl
      timesheetsImportUrl
      timesheetsImportUrlAloha
      listUrl
      reviewUrl
      previewUrl
      employeesSpreadsheetUrl
      taxesImportUrl
      preflightUrl
      paperChecksSettingsUrl
      checkCodeUuid
      checkCodeName
      previousPayPeriodUuid
      previousPayPeriodStartDate
      previousPayPeriodEndDate
      payGroupUuids
      isManual
      previousProviderPayrollType
    }
  }
`

const PayPeriodProvider = ({
  client,
  payroll_id,
  children
}: PayrollRouteParams & { children?: React.ReactNode }) => {
  const key = ['payroll.current', client, payroll_id]
  const apolloClient = useApolloClient()

  const {
    data: payPeriod,
    refetch,
    isLoading
  } = useQuery(key, async (): Promise<any> => {
    // TODO: Can remove this once isLegacyPayrollId is false for *EVERY* route
    if (isLegacyPayrollId(payroll_id)) {
      const payPeriodWeb = (await PayrollResource.getStore({
        client: client!,
        payroll_id
        // esx-web is not actually deliverying a precise PayPeriod type -- it has some extra esx-web specific fields such as the secure id
        // notably, payrollId is not url encoded, whereas payroll_id is
      }).queryRecord()) as PayPeriod & { payrollId: string }

      return {
        ...payPeriodWeb,
        // TODO: instead of adding an entire extra redirect in esx-web, similar to the other URLs, hard code the value here
        // this entire else-if-branch is legacy code, and it and the preflightUrl hack can be removed as secureID requirements are
        // gradually dropped out of the payroll workflow.
        preflightUrl: `/${client}/payroll/details/preflight/${payPeriodWeb.uuid}?id=${payPeriodWeb.payrollId}`
      }
    }

    const response = await apolloClient.query<
      GetPayPeriodByUuidQuery,
      GetPayPeriodByUuidQueryVariables
    >({
      query: PayPeriodQuery,
      variables: {
        payPeriodUuid: payroll_id
      }
    })

    return PayrollResource.serializer.deserialize(response.data.payperiod)
  })

  /*
   * we know that data will always resolve because useQuery will suspend so
   * it's safe to use `data!`
   */
  return (
    <Provider
      createStore={() =>
        createStore({
          payPeriod: payPeriod!,
          refetch,
          isLoading
        })
      }
    >
      {children}
    </Provider>
  )
}

export { useStore as usePayPeriod, PayPeriodProvider }
