// TODO: properly import this type from node-fetch (this is just util code copied from fetch)
const baseFetchArgs = (
  contentType = 'application/json;charset=utf-8',
  requestInitProps: Partial<RequestInit>
): RequestInit => ({
  headers: {
    'Content-Type': contentType
  },
  credentials: 'same-origin',
  ...requestInitProps
})

export const defaultPostFetchArgs = (
  contentType = 'application/json;charset=utf-8'
): RequestInit => baseFetchArgs(contentType, { method: 'POST' })

export const defaultDeleteFetchArgs = (
  contentType = 'application/json;charset=utf-8'
): RequestInit => baseFetchArgs(contentType, { method: 'DELETE' })

export const defaultUpdateFetchArgs = (
  contentType = 'application/json;charset=utf-8',
  httpMethod = 'POST'
): RequestInit => baseFetchArgs(contentType, { method: httpMethod })

export const defaultGetFetchArgs = (
  contentType = 'application/json;charset=utf-8'
): RequestInit => baseFetchArgs(contentType, { method: 'GET' })
