import * as yup from 'yup'
import { DateTime } from 'luxon'
import { assert } from './assert'

/// A SpaDate is a wrapper around a Luxon DateTime that enforces the restriction
/// that the timestamp must be a date boundary (i.e., 00:00:00.000)
///
/// This class follows conventions from Luxon...
///     - SpaDate has no mutating operations.
///     - Method names and parameters are identical to Luxon if possible.
///
/// Important: May not introduce any methods that conflict with SpaDate's
/// responsibility: it must be a date boundary.
export class SpaDate {
  private static readonly schema = yup.string()

  private constructor(private that: DateTime) {
    if (this.that.isValid) {
      assert(this.that.hour === 0, 'zero hr')
      assert(this.that.minute === 0, 'zero min')
      assert(this.that.second === 0, 'zero sec')
      assert(this.that.millisecond === 0, 'zero ms')
      assert(this.that.zone.type === 'local', 'local date')
    }
  }

  // This is a utility for creating skeleton dates (for screen loaders)
  static fromInvalid(reason: string): SpaDate {
    return new SpaDate(DateTime.invalid(reason))
  }

  static fromString(s: unknown): SpaDate {
    if (s instanceof SpaDate) {
      return s
    }

    const validObj = SpaDate.schema.validateSync(s)

    // Must support both Z dates and YYYY-MM-DD formats, until the server switches to only
    // the latter. Then we can add enforcement of the YYYY-MM-DD format.
    if (validObj?.endsWith('Z')) {
      // Luxon fromISO recognizes that 'Z' means UTC, but converts the time to local:
      const local = DateTime.fromISO(validObj)

      // In the local time, this will be the day before. Get back to the start of the next day by
      // subtracting the local's time zone's offset. (The offset for America/New_york is -4 hours
      // or -5 hours, which explains the minus rather than plus.)
      return new SpaDate(local.minus({ minutes: local.offset }))
    }
    if (validObj) {
      return new SpaDate(DateTime.fromISO(validObj))
    }
    return SpaDate.fromInvalid('null')
  }

  static fromStringNullable(s?: unknown): SpaDate | undefined {
    return s ? SpaDate.fromString(s) : undefined
  }

  private static today = (): DateTime => DateTime.local().startOf('day')

  static fromToday(): SpaDate {
    return new SpaDate(SpaDate.today())
  }

  static fromRecent(): SpaDate {
    return new SpaDate(SpaDate.today()).minus(1 + Math.floor(5 * Math.random()))
  }

  toMillis(): number {
    return this.that.toMillis()
  }

  plus(d: number): SpaDate {
    assert(d === Math.floor(d), 'non-int')
    return new SpaDate(this.that.plus({ days: d }))
  }

  minus(d: number): SpaDate {
    assert(d === Math.floor(d), 'non-int')
    return new SpaDate(this.that.minus({ days: d }))
  }

  isGreater(d: SpaDate): boolean {
    return this.that.toMillis() > d.that.toMillis()
  }

  getDay(): number {
    return this.that.weekday
  }

  getYear(): number {
    return this.that.year
  }

  toString(): string {
    return this.that.toString()
  }

  // Must implement toJSON because some tests use serialization/deserialization to construct SpaDate instances.
  public toJSON(): string | null {
    return this.that.toJSON()
  }

  // Use this routine to format anything that conceptually does NOT have a time.
  // date must be at midnight.
  toFormat(
    format = 'D' // https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
  ): string {
    if (!this.that.isValid)
      return this.that.invalidReason ? this.that.invalidReason : 'invalid date'

    // In some settings, the date object is "frozen" by a call to Object.freeze().
    // Not sure when/why this happens. And it happens that Luxon modifies the internal
    // data of a DateTime during a toFormat() call. Not sure why, but it does. To work
    // around, we allocate a temporary DateTime by calling `plus(0)`.
    return this.that.plus(0).toFormat(format)
  }

  toJSDate() {
    return this.that.toJSDate()
  }

  static formatDateRange = (
    start: SpaDate,
    end: SpaDate,
    fmt = 'D' // https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
  ): string => `${start.toFormat(fmt)} - ${end.toFormat(fmt)}`
}
