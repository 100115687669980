import { RestResource } from '@local/rest-data'
import { contentType } from '@local/payroll/shared/types/content'
import { withMirageServerDo } from '@local/shared-services'

export const CheckPdfsResource = new RestResource<string>({
  resource: ':client/payroll/:payperiod_uuid/checksPdf/:check_uuid',
  contentType: contentType
})

export const CheckPdfsResourceEcPayroll = new RestResource<string>({
  resource: 'payroll/:payperiod_uuid/checksPdf/:check_uuid',
  contentType: contentType
})

//#region Mirage support
withMirageServerDo((server, _faker) => {
  server.get(CheckPdfsResource.endpointTemplate(), () => {
    return ['https://pos.toasttab.com']
  })
})
