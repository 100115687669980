import * as yup from 'yup'
import { DateTime, Duration, DurationObject } from 'luxon'
import { assert } from './assert'

/// A SpaDateTime is a wrapper around a Luxon DateTime that enforces the restriction
/// that the timestamp must be local.
///
/// This class follows conventions from Luxon...
///     - SpaDateTime has no mutating operations.
///     - Method names and parameters are identical to Luxon if possible.
///
/// Important: May not introduce any methods that conflict with SpaDateTime's
/// responsibility: it is an immutable timestamp that must be local.
export class SpaDateTime {
  private static readonly schema = yup.string()

  private constructor(private readonly that: DateTime) {
    if (this.that.isValid) {
      assert(this.that.zone.type === 'local', 'local zone')
    }
  }

  static fromString(obj: unknown): SpaDateTime {
    if (obj instanceof SpaDateTime) {
      return obj
    }
    const validObj = SpaDateTime.schema.validateSync(obj)

    // Luxon fromISO recognizes that 'Z' means UTC, and converts to local
    return new SpaDateTime(
      validObj?.endsWith('Z')
        ? DateTime.fromISO(validObj)
        : DateTime.invalid(validObj ? 'noZ' : 'null')
    )
  }

  static fromStringNullable(s?: unknown): SpaDateTime | undefined {
    return s ? SpaDateTime.fromString(s) : undefined
  }

  static fromNow(): SpaDateTime {
    return new SpaDateTime(DateTime.local())
  }

  static fromRecent(): SpaDateTime {
    return new SpaDateTime(
      DateTime.local().minus({
        minutes: Math.floor(2880 * Math.random())
      })
    )
  }

  static fromPostingDeadline(): SpaDateTime {
    return new SpaDateTime(
      DateTime.local()
        .startOf('day')
        .plus(Duration.fromObject({ hours: 15 }))
    ) // 3pm today.
    // Difference between local and CST is glossed over; routine is used only for mocks.
  }

  isGreater(d: SpaDateTime): boolean {
    return this.toMillis() > d.toMillis()
  }

  isValid(): boolean {
    return this.that.isValid
  }

  isToday(): boolean {
    return (
      this.that.toLocal().startOf('day').valueOf() ===
      DateTime.local().startOf('day').valueOf()
    )
  }

  toMillis(): number {
    return this.that.toMillis()
  }

  plus(d: number | Duration | DurationObject): SpaDateTime {
    return new SpaDateTime(this.that.plus(d))
  }

  minus(d: number | Duration | DurationObject): SpaDateTime {
    return new SpaDateTime(this.that.minus(d))
  }

  toString(): string {
    return this.that.toString()
  }

  // Must have toJSON because some of our tests create instances by serialize/deserialize
  // Must convert to UTC for this purpose, so that serialization through fromString works.
  public toJSON(): string | null {
    return this.that.toUTC().toJSON()
  }

  toFormat(
    fmt: string, // https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
    opts?: Record<any, any>
  ): string {
    // The plus(0) is to avoid a bug that happens when the date is "frozen" by a call
    // to Object.freeze, as Luxon tries to modify internal fields in toFormat calls.
    // (Frozen means that attempts to modify will crash.)
    return this.that.plus(0).toFormat(fmt, opts)
  }
}
