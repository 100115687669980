import { FactorySerializer, Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { EarningsBucket } from '@local/payroll/experience/details/models'

const EarningsBucketSerializer = new FactorySerializer(
  (payload) => new EarningsBucket(payload)
)

// resource hits esx-web
export const EarningsBucketResource = new Resource<EarningsBucket>(
  EarningsBucketSerializer,
  ':client/payroll/details/earningBuckets/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server) => {
  server.db.createCollection(
    'payrollEarningBucket',
    [
      EarningsBucket.of(0, 2),
      EarningsBucket.of(500, 2),
      EarningsBucket.of(1000, 5),
      EarningsBucket.of(1500, 6),
      EarningsBucket.of(2000, 4),
      EarningsBucket.of(2500, 3),
      EarningsBucket.of(3000, 1)
    ].map((e) => EarningsBucketResource.serializer.serialize(e))
  )

  server.get(
    EarningsBucketResource.endpointTemplate(),
    function (schema) {
      return schema.db.payrollEarningBucket
    },
    { timing: 4000 }
  )
})
//#endregion
