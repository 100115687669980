import { Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import {
  EmployeeEarningFactory,
  PayrollEmployeeFactory,
  PayCheckFactory
} from '@local/payroll/shared/models/factories'

import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { PayrollActionSerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import {
  PayCheckEarning,
  PayrollEmployee,
  CheckCode,
  EarningCode
} from '@local/payroll/shared/types/graphDeprecated'

export type AddEarningModelV1 = {
  employeeId: string
  checkCodeUuid: string
  earningCodeUuid: string
  levels: any
  positionId: string
  paystub: number
  week: number
  rate: number
  hours: number
  amount: number
}

export type AddEarningModel = {
  employeeId: string
  checkCodeUuid: string
  earningCodeUuid: string
  levels: any
  positionUuid: string
  paystub: number
  week: number
  rate: number
  baseRate: number
  hours?: number
  amount?: number
}

// This resource is in esx-web
export const AddEarningResourceV1 = new Resource<
  AddEarningModelV1,
  PayrollAction<PayrollEmployee>
>(
  new PayrollActionSerializer<PayrollEmployee>(PayrollEmployeeFactory.create),
  ':client/payroll/:payroll_id/employees/:employee_id/earning/:earning_id'
)

// This resource is in esx-web
export const AddEarningResource = new Resource<
  AddEarningModel,
  PayrollAction<PayrollEmployee>
>(
  new PayrollActionSerializer<PayrollEmployee>(PayrollEmployeeFactory.create),
  ':client/payroll/:payroll_id/employees/:employee_id/earningByUuid/:earning_id'
)

//#region mirage
withMirageServerDo((server, faker) => {
  const BuildMockEarning = (earning: AddEarningModelV1 | AddEarningModel) => {
    const checkCodes = server.db.checkCode.where({})
    const earningCodes = server.db.earningCode.where({})
    const rate = earning.rate
    const hours = earning.hours

    return EmployeeEarningFactory.copy({
      id: faker.random.number() + '',
      uuid: faker.random.uuid(),
      name: faker.helpers.randomize(['Regular', 'Overtime']),
      location: faker.helpers.randomize([
        'Streeterville',
        'River North',
        'Wicker Park',
        'Old Town',
        'Lincoln Park'
      ]),
      job: faker.helpers.randomize([
        'server',
        'bartender',
        'chef',
        'owner',
        'hostess',
        'cook',
        'line-cook'
      ]),
      rate: rate,
      baseRate: rate,
      hours: hours!,
      checkCode: faker.helpers.randomize(checkCodes),
      earningCode: faker.helpers.randomize(earningCodes),
      isFirstWeek: faker.helpers.randomize([false, true]),
      amount: earning.amount!
    })
  }
  server.post(
    AddEarningResourceV1.endpointTemplate(),
    function (schema, request) {
      const earning: AddEarningModelV1 = JSON.parse(request.requestBody)
      const { earning_id } = request.params
      const isAdding = earning_id === '0' //NOTE: this is the magical number that determines if the earning is "new"

      const employee: PayrollEmployee = schema.db.payrollEmployee.find(
        earning.employeeId
      )

      const paystubs = employee.paystubs.map((paystub) => {
        if (paystub.number !== earning.paystub) return paystub

        if (isAdding) {
          const newEarning: PayCheckEarning = BuildMockEarning(earning)
          paystub.earnings.push(newEarning)
        } else {
          const earnings = paystub.earnings.map((existing) => {
            if (existing.id !== earning_id) return existing

            const checkCode: CheckCode = server.db.checkCode.findBy({
              value: earning.checkCodeUuid
            })
            const earningCode: EarningCode = server.db.earningCode.findBy({
              value: earning.earningCodeUuid
            })

            const update: PayCheckEarning = {
              ...existing,
              ...earning,
              checkCode,
              earningCode,
              name: earningCode.label
            }

            return update
          })

          paystub = PayCheckFactory.copy({ ...paystub, earnings })
        }

        return paystub
      })

      const result = server.db.payrollEmployee.update(employee.id, { paystubs })

      return PayrollAction.of(result)
    }
  )

  server.post(
    AddEarningResource.endpointTemplate(),
    function (schema, request) {
      const earning: AddEarningModel = JSON.parse(request.requestBody)
      const { earning_id } = request.params
      const isAdding = earning_id === '0' //NOTE: this is the magical number that determines if the earning is "new"

      const employee: PayrollEmployee = schema.db.payrollEmployee.find(
        earning.employeeId
      )

      const paystubs = employee.paystubs.map((paystub) => {
        if (paystub.number !== earning.paystub) return paystub

        if (isAdding) {
          const newEarning: PayCheckEarning = BuildMockEarning(earning)
          paystub.earnings.push(newEarning)
        } else {
          const earnings = paystub.earnings.map((existing) => {
            if (existing.id !== earning_id) return existing

            const checkCode: CheckCode = server.db.checkCode.findBy({
              value: earning.checkCodeUuid
            })
            const earningCode: EarningCode = server.db.earningCode.findBy({
              value: earning.earningCodeUuid
            })

            const update: PayCheckEarning = {
              ...existing,
              ...earning,
              checkCode,
              earningCode,
              name: earningCode.label
            }

            return update
          })

          paystub = PayCheckFactory.copy({ ...paystub, earnings })
        }

        return paystub
      })

      const result = server.db.payrollEmployee.update(employee.id, { paystubs })

      return PayrollAction.of(result)
    }
  )
})
//#endregion
