import { usePayPeriod } from '@local/payroll/experience/details/stores/PayPeriodStore'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import { PayrollStatusHelpers } from '@local/payroll/shared/utils/payrollStatus/helpers'
import { BaseStepProps } from '@toasttab/buffet-pui-stepper'
import { useTranslation } from 'react-i18next'

export type PayrollStep =
  | 'Preview payroll'
  | 'Employee earnings'
  | 'Review & submit'

interface usePageHeaderProps {
  currentStep?: PayrollStep
}

type StepConfig = {
  steps: Array<StepModel>
}

export type StepModel = {
  title: string
  link?: string
  state: BaseStepProps['state']
  isActive?: boolean
}

type Hook<TParams, TResult> = (params: TParams) => TResult

export const usePageHeader: Hook<usePageHeaderProps, StepConfig> = ({
  currentStep
}) => {
  const { t } = useTranslation('payroll')

  const { status, reviewUrl, preflightUrl, employeesSpreadsheetUrl } =
    usePayPeriod((state) => state.payPeriod)

  //if we do not have any current step, we don't want to display anything
  if (currentStep === undefined) {
    return { steps: [] }
  }

  const getStatus = (step: PayrollStep): BaseStepProps['state'] => {
    if (PayrollStatusHelpers.isPosted(status)) return 'complete'
    if (currentStep === step) return 'active'

    switch (step) {
      case 'Preview payroll':
        return 'complete'
      case 'Employee earnings':
        return currentStep === 'Preview payroll' ? 'inactive' : 'complete'
      case 'Review & submit':
        return 'inactive'
      default:
        return 'error'
    }
  }

  let isNavigationEnabled = false

  if (employeesSpreadsheetUrl === undefined) {
    console.info(
      `disabling navigation in page header because spreadsheet url is not yet available, 
        once it is available from the server we can resume expected behavior`
    )
  } else {
    isNavigationEnabled =
      status === PayrollStatus.posted || status === PayrollStatus.generated_ach
  }

  return {
    steps: [
      {
        title: t('preflight.stepper'),
        link: isNavigationEnabled ? preflightUrl : undefined,
        state: getStatus('Preview payroll'),
        isActive: currentStep === 'Preview payroll'
      },
      {
        title: t('spreadsheet.stepper'),
        link: isNavigationEnabled ? employeesSpreadsheetUrl : undefined,
        state: getStatus('Employee earnings'),
        isActive: currentStep === 'Employee earnings'
      },
      {
        title: t('review.stepper'),
        link: isNavigationEnabled ? reviewUrl : undefined,
        isActive: currentStep === 'Review & submit',
        state: getStatus('Review & submit')
      }
    ]
  }
}
