import { ISerializer } from './index'

export class BaseSerializer<SourceType, ResponseType = any>
  implements ISerializer<SourceType, ResponseType>
{
  /**
   * takes a model and serializes it into JSON to be sent to the server
   * @param model the data structure you work with inside the application
   */
  serialize(model: SourceType): any {
    return { ...model } as unknown
  }
  /**
   * takes JSON from the server and converts it into a model
   * @param model JSON from a server response
   */
  deserialize(model: any): ResponseType {
    return model as ResponseType
  }
}

export class ModelSerializer<T> implements ISerializer<T, T> {
  constructor(readonly tConstructor: { new (obj: unknown): T }) {}

  serialize(model: T): any {
    return { ...model } as unknown
  }

  deserialize(model: any): T {
    return new this.tConstructor(model)
  }
}

export class FactorySerializer<T> implements ISerializer<T, T> {
  constructor(readonly create: (obj: unknown) => T) {}

  serialize(model: T): any {
    return { ...model } as unknown
  }

  deserialize(model: any): T {
    return this.create(model)
  }
}
