import {
  AddBulkEarningModel,
  AddBulkEarningResourceEcPayroll
} from './resource'

export interface AddBulkEarningMutationProps {
  client: string
  uuid: string
  earning: AddBulkEarningModel
}

interface AddBulkEarningMutationResult {
  uuid: string
  status:
    | 'ADDED'
    | 'UPDATED'
    | 'PAYROLL_EMPLOYEE_NOT_FOUND'
    | 'POSITION_NOT_FOUND'
    | 'FAILED_UNKNOWN'
}

export const AddBulkActionMutation = async ({
  client,
  uuid,
  earning
}: AddBulkEarningMutationProps): Promise<
  Array<AddBulkEarningMutationResult>
> => {
  const store = AddBulkEarningResourceEcPayroll.getStore({
    client,
    payroll_id: uuid
  })

  const response = await store.createRecord(earning)

  if (response.errors) throw response.errors

  return response.result
}
