import * as React from 'react'
import { PageError } from '@local/payroll/shared/error-boundary/PageError'

type PageProps = {
  background?: 'light' | 'dark'
}

export const Page = ({
  children,
  background = 'light',
  ...props
}: React.PropsWithChildren<React.JSX.IntrinsicElements['div'] & PageProps>) => {
  React.useLayoutEffect(() => {
    if (background === 'dark') {
      document.getElementsByTagName('html')[0].classList.add('dark')
    }

    return () => {
      if (background === 'dark') {
        document.getElementsByTagName('html')[0].classList.remove('dark')
      }
    }
  }, [background])

  return (
    <div className='max-w-screen-xl xl:mx-auto' {...props}>
      <PageError />
      {children}
    </div>
  )
}
