import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertModal } from '@local/payroll/shared/components/modals'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useResetPayrollModal } from './hooks/useResetPayrollModal'
import { usePayPeriod } from '@local/payroll/experience/details/stores/PayPeriodStore'

export const ResetPayrollModal = () => {
  const { t } = useTranslation('payroll')
  const { reset, isOpen, close, isLoading } = useResetPayrollModal()

  const payPeriod = usePayPeriod((state) => state.payPeriod)

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    reset(payPeriod.uuid)
  }

  if (!isOpen) {
    return null
  }

  return (
    <AlertModal
      title={t(`common.modal.title.reset`)}
      isOpen
      onRequestClose={close}
    >
      <>
        {isLoading || !payPeriod ? (
          <div className='flex flex-col items-center justify-center h-full p-10 space-y-4'>
            <MerryGoRound />
            <div>{t(`common.modal.loading.reset`)}</div>
          </div>
        ) : (
          <>
            <AlertModal.Body>
              <p className='type-large'>{t(`common.modal.message.reset`)}</p>
            </AlertModal.Body>
            <AlertModal.Footer>
              <Button variant='secondary' onClick={close}>
                {t(`common.action.buttons.cancel`)}
              </Button>
              <Button
                onClick={handleClick}
                testId={`${t(`common.action.buttons.reset`)}-confirmed`}
              >
                {t(`common.action.buttons.reset`)}
              </Button>
            </AlertModal.Footer>
          </>
        )}
      </>
    </AlertModal>
  )
}
