import { DeductionCode } from '@local/payroll/experience/details/models'
import * as yup from 'yup'

export const DeductionCodeFactory = {
  create(obj: unknown): DeductionCode {
    return yup
      .object({
        value: yup.string().defined(),
        label: yup.string().defined(),
        isActive: yup.boolean().required(),
        isSameDayPayRedemption: yup.boolean().required(),
        isTipsPayoutRedemption: yup.boolean().required()
      })
      .defined()
      .validateSync(obj)
  },
  copy(obj: DeductionCode) {
    return this.create(obj)
  }
}
