import { RestResource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { NoteworthySummary } from '@local/payroll/experience/details/models'

// resource hits esx-web
export const NoteworthySummaryResource = new RestResource<NoteworthySummary>({
  resource: ':client/payroll/details/noteworthySummary/:payroll_id'
})

//#region Mirage support
withMirageServerDo((server) => {
  server.db.createCollection(
    'payrollNoteworthySummary',
    [
      new NoteworthySummary('1', 'TERMINATIONS', []),
      new NoteworthySummary('2', 'EMPLOYEES_WITHOUT_NETPAY', []),
      new NoteworthySummary('3', 'UNPAID_ACTIVE_EMPLOYEES', []),
      new NoteworthySummary('4', 'EMPLOYEES_WITH_TIP_MAKEUP', [])
    ].map((n) => NoteworthySummaryResource.serializer.serialize(n))
  )

  server.get(
    NoteworthySummaryResource.endpointTemplate(),
    function (schema) {
      return schema.db.payrollNoteworthySummary
    },
    { timing: 4000 }
  )
})
//#endregion
