import * as React from 'react'
import { useState } from 'react'
import locaclI18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { I18nextProvider } from 'react-i18next'
import { useEcProps } from '@toasttab/ec-banquet-props'

const getLocalInstance = () => {
  return locaclI18n.use(LanguageDetector).init({
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['htmlTag', 'navigator']
    }
  })
}

export const I18nProvider = ({ children }: { children?: React.ReactNode }) => {
  const { i18n: banquetI18n } = useEcProps()

  const [i18n] = useState(() => banquetI18n || getLocalInstance())

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
