import {
  EmployeeDeductionFactory,
  EmployeeEarningFactory
} from '@local/payroll/shared/models/factories'

import { RestResource } from '@local/rest-data'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { withMirageServerDo } from '@local/shared-services'
import {
  PayCheckDeduction,
  PayCheckEarning
} from '@local/payroll/shared/types/graphDeprecated'

export interface EmployeeTax {
  name: string
  amount: number
  subjectWages: number
  ytd: number
}

export interface GrossToNetPayCheck {
  number: number
  grossEarnings: number
  totalDeductions: number
  totalTaxes: number
  netEarnings: number
  earnings: Array<PayCheckEarning>
  deductions: Array<PayCheckDeduction>
  taxes: Array<EmployeeTax>
}

export interface GrossToNetCalculation {
  grossEarnings: number
  totalDeductions: number
  totalTaxes: number
  netEarnings: number

  paychecks: Array<GrossToNetPayCheck>
}

export const GrossToNetCalculationResource = new RestResource<
  PayrollAction<GrossToNetCalculation>
>({
  // This resource hits esx-web
  resource: ':client/payroll/:payroll_id/:employee_id/calculateGrossToNet'
})

withMirageServerDo((server, faker) => {
  const BuildMockEarning = () => {
    return EmployeeEarningFactory.copy({
      id: faker.random.number() + '',
      uuid: faker.random.uuid(),
      name: faker.helpers.randomize(['Regular', 'Overtime']),
      location: faker.helpers.randomize([
        'Streeterville',
        'River North',
        'Wicker Park',
        'Old Town',
        'Lincoln Park'
      ]),
      job: faker.helpers.randomize([
        'server',
        'bartender',
        'chef',
        'owner',
        'hostess',
        'cook',
        'line-cook'
      ]),
      rate: 0,
      baseRate: 0,
      hours: faker.random.number(8),
      checkCode: { label: 'Sample', value: '1345' },
      earningCode: {
        label: 'Sample',
        value: '1345',
        isHourly: true,
        isOT: false
      },
      isFirstWeek: true,
      amount: faker.random.number(500)
    })
  }

  const BuildMockDeduction = () => {
    return EmployeeDeductionFactory.copy({
      id: faker.random.number() + '',
      deductionCodeId: faker.random.number() + '',
      name: faker.helpers.randomize(['Federal', 'State']),
      amount: faker.random.number(10),
      isPercentage: faker.random.boolean(),
      isReadOnly: Math.random() <= 0.2
    })
  }

  const BuildMockTax = () => {
    return {
      name: faker.random.arrayElement([
        'FICA',
        'Federal Income Tax',
        'Paid Family Medical Leave',
        'SDI',
        'FIT',
        'SIT',
        'Medicare'
      ]),
      amount: faker.random.number(200),
      subjectWages: faker.random.number({ max: 4000, min: 1000 }),
      ytd: faker.random.number(2000)
    } as EmployeeTax
  }

  const BuildMockPaycheck = () => {
    const earnings = [BuildMockEarning(), BuildMockEarning()]
    const deductions = [BuildMockDeduction(), BuildMockDeduction()]
    const taxes = [BuildMockTax(), BuildMockTax()]

    const grossEarnings = earnings.reduce((p, c) => p + c.amount, 0)
    const totalDeductions = deductions.reduce((p, c) => p + c.amount, 0)
    const totalTaxes = taxes.reduce((p, c) => p + c.amount, 0)
    const netEarnings = grossEarnings - totalDeductions - totalTaxes

    return {
      number: faker.random.number(),
      grossEarnings,
      totalDeductions,
      totalTaxes,
      netEarnings,
      earnings,
      deductions,
      taxes
    } as GrossToNetPayCheck
  }

  server.post(GrossToNetCalculationResource.endpointTemplate(), () => {
    const paychecks = [BuildMockPaycheck(), BuildMockPaycheck()]
    const result: GrossToNetCalculation = {
      grossEarnings: paychecks.reduce((p, c) => p + c.grossEarnings, 0),
      totalDeductions: paychecks.reduce((p, c) => p + c.totalDeductions, 0),
      totalTaxes: paychecks.reduce((p, c) => p + c.totalTaxes, 0),
      netEarnings: paychecks.reduce((p, c) => p + c.netEarnings, 0),
      paychecks
    }

    return PayrollAction.of(result, [
      //un comment if you want to show the error state
      // SpaErrorModel.of(500, 'unable to calculate')
    ])
  })
})
