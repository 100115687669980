import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import { WarningOutlineDisplayIcon } from '@toasttab/buffet-pui-icons'
import { SpaErrorModel } from '@local/payroll/shared/models/spaErrorModel'
import { AlertModal } from '..'
import { useAppError } from '@local/payroll/shared/hooks/useAppError'

export const AppErrorModal = () => {
  const { t } = useTranslation('payroll')
  const { useAppErrorContext, useClearAppError, useHasAppError } = useAppError()

  const hasError = useHasAppError()
  const clear = useClearAppError()
  const errorContext = useAppErrorContext()

  if (!hasError) return null

  const alertTitle = (
    <div className='flex items-center space-x-3'>
      <div>
        <WarningOutlineDisplayIcon aria-label='warning icon' size='lg' />
      </div>
      <div>{t('common.modal.header', { action: errorContext.action })}</div>
    </div>
  )
  return (
    <AlertModal title={alertTitle} isOpen onRequestClose={clear}>
      <AlertModal.Body>
        <SpaErrors
          action={errorContext.action}
          errors={errorContext.spaErrors}
        />
      </AlertModal.Body>
      <AlertModal.Footer>
        <Button onClick={clear}>{t('common.action.buttons.okay')}</Button>
      </AlertModal.Footer>
    </AlertModal>
  )
}

const SpaErrors = (props: { action?: string; errors?: SpaErrorModel[] }) => {
  const { t } = useTranslation('payroll')
  if (!props.errors || props.errors.length === 0) {
    return (
      <p className='type-default text-secondary'>
        {props.action ? (
          <Trans
            t={t}
            i18nKey={`common.modal.message.errors.${props.action}`}
            action={props.action}
          >
            Your payroll failed to {{ action: props.action }}. Please wait a few
            minutes and try again. If this issue continues,
            <a href='https://central.toasttab.com/s/article/How-to-Submit-a-Ticket-to-Customer-Care'>
              contact Customer Care.
            </a>
          </Trans>
        ) : (
          t('common.modal.message.errors.generic')
        )}
      </p>
    )
  }

  return (
    <div>
      {props.errors.map((thisError, index) => (
        <div key={index}>
          <p className='mt-8 type-large'>
            {thisError.errorCode
              ? t(`errors.${props.action}.${thisError.errorCode}`)
              : t('common.modal.message.errors.generic')}
          </p>
        </div>
      ))}
    </div>
  )
}
