import * as React from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

const { Provider, useStore } = createContext<Store>()
interface Store {
  modal?: string
  context?: any
  _open: (key: string, context?: any) => void
  close: () => void
}

const createStore = () => {
  return create<Store>((set) => ({
    modal: undefined,
    context: undefined,
    _open: (key: string, context?: any) => set({ modal: key, context }),
    close: () => set({ modal: undefined, context: undefined })
  }))
}

const ModalContext = ({ children }: { children?: React.ReactNode }) => (
  <Provider createStore={() => createStore()}>{children}</Provider>
)
function useModalContext<TContext>(defaultValue: TContext) {
  return useStore((state) => (state.context as TContext) ?? defaultValue)
}

function useOpenModal<TContext>(key: string) {
  const open = useStore((state) => state._open)

  return (context: TContext) => {
    open(key, context)
  }
}

function useIsModalOpen(key: string) {
  return useStore((state) => state.modal === key)
}
function useCloseModal() {
  return useStore((state) => state.close)
}

function ModalHooksFor<TContext = void>(key: string, initialState?: TContext) {
  return {
    useIsModalOpen: () => useIsModalOpen(key),
    useOpenModal: () => useOpenModal<TContext>(key),
    useCloseModal,
    useModalContext: () => useModalContext<TContext>(initialState as TContext)
  }
}
function useHasOpenModal() {
  return useStore((state) => state.modal !== undefined)
}

export { ModalHooksFor, ModalContext, useHasOpenModal }
