import { Resource } from '@local/rest-data'
import { withMirageServerDo } from '@local/shared-services'
import { PayrollAction } from '@local/payroll/shared/models/payrollAction'
import { PayrollActionArraySerializer } from '@local/payroll/shared/api/serializers/payrollAction'
import {
  PayrollEmployeeFactory,
  PayCheckFactory
} from '@local/payroll/shared/models/factories'
import {
  PayCheck,
  PayrollEmployee
} from '@local/payroll/shared/types/graphDeprecated'

// This resource hits esx-web
export const PayViaCheckAndScheduleResource = new Resource<PayrollAction>(
  new PayrollActionArraySerializer<PayrollEmployee>(
    PayrollEmployeeFactory.create
  ),
  ':client/payroll/details/paybycheckandreschedule/:payroll_id'
)

// This resource hits esx-web
export const PayViaCheckResource = new Resource<PayrollAction>(
  new PayrollActionArraySerializer<PayrollEmployee>(
    PayrollEmployeeFactory.create
  ),
  ':client/payroll/details/paybycheck/:payroll_id'
)

//#region Mirage support
withMirageServerDo((server) => {
  server.post(
    PayViaCheckAndScheduleResource.endpointTemplate(),
    function (schema, _request) {
      const employees: PayrollEmployee[] = schema.db
        .payrollEmployee as unknown as PayrollEmployee[]
      employees.forEach((ee) => {
        ee.paystubs.forEach((ps: PayCheck, index: number) => {
          ee.paystubs[index] = PayCheckFactory.copy({
            id: ps.id,
            paidByCheck: true,
            number: ps.number,
            earnings: ps.earnings,
            deductions: ps.deductions,
            isVoid: ps.isVoid,
            isReadOnly: ps.isReadOnly,
            isManual: ps.isManual,
            checkCode: ps.checkCode
          })
        })
      })

      return PayrollAction.of(employees, [], '')
    }
  )

  server.post(
    PayViaCheckResource.endpointTemplate(),
    function (schema, _request) {
      const employees: PayrollEmployee[] = schema.db
        .payrollEmployee as unknown as PayrollEmployee[]
      employees.forEach((ee) => {
        ee.paystubs.forEach((ps: PayCheck, index: number) => {
          ee.paystubs[index] = PayCheckFactory.copy({
            id: ps.id,
            paidByCheck: true,
            number: ps.number,
            earnings: ps.earnings,
            deductions: ps.deductions,
            isVoid: ps.isVoid,
            isReadOnly: ps.isReadOnly,
            isManual: ps.isManual,
            checkCode: ps.checkCode
          })
        })
      })

      return PayrollAction.of(employees, [], '')
    }
  )
})
//#endregion
