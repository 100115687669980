import { safeLazy } from '@local/lazy'

const LazyEmployees = safeLazy(() => {
  return import(/*webpackChunkName: "experience.employees" */ './employees')
})

const LazyReviewPage = safeLazy(() => {
  return import(/*webpackChunkName: "experience.review" */ './review/page')
})

export { LazyEmployees, LazyReviewPage }
