import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'

import { useGlobalViewModel } from '@local/payroll/shared/hooks/legacy/useGlobalViewModel'

type AlohaTimesheetsImportModel = {
  step: () => number
  stepNextConfirmed: () => void
  stepPrevious: () => void
  leftMenu: {
    nextLink: (value: string) => string
    previousLink: (value: string) => string
  }
}

type AlohaNextButtonProps = {
  previousUrl: string
  nextUrl: string
  className?: string
}

const fallbackVM = {
  step() {
    return 2
  },
  stepNextConfirmed() {
    window.history.back()
  },
  stepPrevious() {
    window.history.back()
  },
  leftMenu: {
    nextLink() {
      return ''
    },
    previousLink() {
      return ''
    }
  }
}

export const Actions = ({
  className,
  previousUrl,
  nextUrl
}: AlohaNextButtonProps) => {
  const { t } = useTranslation('payroll')
  const { vm } = useGlobalViewModel<AlohaTimesheetsImportModel>(fallbackVM)

  const handlePrevious = React.useCallback(() => {
    vm.leftMenu.previousLink(previousUrl)
    vm.stepPrevious()
  }, [previousUrl, vm])

  const handleNext = React.useCallback(() => {
    vm.leftMenu.nextLink(nextUrl)
    vm.stepNextConfirmed()
  }, [nextUrl, vm])

  return (
    <ButtonGroup>
      <Button
        variant='secondary'
        onClick={handlePrevious}
        className={className}
      >
        {t('common.previous')}
      </Button>
      <Button variant='primary' onClick={handleNext} className={className}>
        {t('common.next')}
      </Button>
    </ButtonGroup>
  )
}
