import { ModalHooksFor } from '@local/payroll/experience/details/stores/ModalStore'
import { SpaErrorModel } from '@local/payroll/shared/models/spaErrorModel'

interface AppError {
  action?: string
  error?: Error | null | unknown
  spaError?: SpaErrorModel
  spaErrors?: SpaErrorModel[]
}

const { useOpenModal, useModalContext, useIsModalOpen, useCloseModal } =
  ModalHooksFor<AppError>('AppError', {
    action: undefined,
    error: undefined,
    spaError: undefined,
    spaErrors: undefined
  })

export const useAppError = () => ({
  useSetAppError: useOpenModal,
  useHasAppError: useIsModalOpen,
  useAppErrorContext: useModalContext,
  useClearAppError: useCloseModal
})
